import React, { useState } from 'react';
import axios from 'axios';

function HeadlineAnalyzer() {
  const [headline, setHeadline] = useState('');
  const [analysis, setAnalysis] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const apiUrl = process.env.REACT_APP_API_URL;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);
    try {
      const response = await axios.post(`${apiUrl}/analyze-headline`, {
        headline
      });
      if (response.data.analysis) {
        setAnalysis(response.data.analysis);
      } else if (response.data.error) {
        throw new Error(response.data.error);
      } else {
        throw new Error('No analysis data received');
      }
    } catch (error) {
      console.error('Error analyzing headline:', error);
      setError(error.message || 'An error occurred while analyzing the headline. Please try again.');
    }
    setIsLoading(false);
  };

  return (
    <div className="max-w-2xl mx-auto">
      <h2 className="text-2xl font-bold mb-4">Headline Analyzer</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block mb-1">Enter your headline:</label>
          <input
            type="text"
            value={headline}
            onChange={(e) => setHeadline(e.target.value)}
            className="w-full p-2 border rounded"
            required
          />
        </div>
        <button
          type="submit"
          className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700"
          disabled={isLoading}
        >
          {isLoading ? 'Analyzing...' : 'Analyze Headline'}
        </button>
      </form>
      {error && (
        <div className="mt-4 text-red-600">
          {error}
        </div>
      )}
      {analysis && (
        <div className="mt-8">
          <h3 className="text-xl font-bold mb-2">Analysis:</h3>
          <div className="bg-gray-100 p-4 rounded">
            <p><strong>Overall Score:</strong> {analysis.overallScore}/100</p>
            <p><strong>Emotion Score:</strong> {analysis.emotionScore}/10</p>
            <p><strong>SEO Score:</strong> {analysis.seoScore}/10</p>
            <p><strong>Clarity Score:</strong> {analysis.clarityScore}/10</p>
            <p><strong>Engagement Score:</strong> {analysis.engagementScore}/10</p>
            <h4 className="font-bold mt-4">Suggestions:</h4>
            <ul className="list-disc pl-5">
              {analysis.suggestions.map((suggestion, index) => (
                <li key={index}>{suggestion}</li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
}

export default HeadlineAnalyzer;