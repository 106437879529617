import React, { useState } from 'react';
import axios from 'axios';
import { FaImage, FaSpinner, FaDownload, FaCopy, FaTrash } from 'react-icons/fa';

function ImageGenerator() {
  const [prompt, setPrompt] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      const response = await axios.post('http://127.0.0.1:5000/generate-ai-image', { prompt });
      setImageUrl(response.data.image_url);
    } catch (err) {
      setError('Failed to generate image. Please try again.');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleCopyUrl = () => {
    navigator.clipboard.writeText(imageUrl);
    alert('Image URL copied to clipboard!');
  };

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white shadow-lg rounded-lg">
      <h2 className="text-3xl font-bold mb-6 text-center text-gray-800">
        <FaImage className="inline-block mr-2 mb-1" />
        AI Image Generator
      </h2>
      <form onSubmit={handleSubmit} className="mb-6">
        <textarea
          value={prompt}
          onChange={(e) => setPrompt(e.target.value)}
          placeholder="Enter a detailed description for the image"
          className="w-full p-3 border border-gray-300 rounded-lg resize-y min-h-[120px] focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          required
        />
        <div className="flex justify-between items-center mt-4">
          <button
            type="submit"
            className="bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700 transition duration-300 ease-in-out flex items-center disabled:bg-blue-400"
            disabled={loading || prompt.trim().length === 0}
          >
            {loading ? (
              <>
                <FaSpinner className="animate-spin mr-2" />
                Generating...
              </>
            ) : (
              <>
                <FaImage className="mr-2" />
                Generate Image
              </>
            )}
          </button>
          <button
            type="button"
            onClick={() => setPrompt('')}
            className="text-gray-600 hover:text-gray-800 transition duration-300 ease-in-out flex items-center"
          >
            <FaTrash className="mr-2" />
            Clear
          </button>
        </div>
      </form>
      {error && <p className="text-red-500 mb-4 text-center">{error}</p>}
      {imageUrl && (
        <div className="bg-gray-100 p-6 rounded-lg shadow-inner">
          <h3 className="text-2xl font-semibold mb-4 text-gray-800">Generated Image:</h3>
          <img src={imageUrl} alt="Generated" className="w-full rounded-lg shadow-xl mb-4" />
          <div className="flex justify-between">
            <a
              href={imageUrl}
              download="generated_image.jpg"
              className="bg-green-500 text-white py-2 px-4 rounded-lg hover:bg-green-600 transition duration-300 ease-in-out flex items-center"
            >
              <FaDownload className="mr-2" />
              Download Image
            </a>
            <button
              onClick={handleCopyUrl}
              className="bg-purple-500 text-white py-2 px-4 rounded-lg hover:bg-purple-600 transition duration-300 ease-in-out flex items-center"
            >
              <FaCopy className="mr-2" />
              Copy Image URL
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default ImageGenerator;