import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { SessionContext } from '../sessionProvider';
import { useContext,useState } from 'react';
import supabase from '../supabase/supabaseClient';

import Feedback from '../components/feedback';

const UserDashboard = () => {

    const { session , totalWordsGenerated, generatedDescriptionCount} = useContext(SessionContext);

    const { planLimit } = useContext(SessionContext);
    const {userPlan}= useContext(SessionContext);

    const descriptionsLeft = planLimit - generatedDescriptionCount;
    const progress = (generatedDescriptionCount / planLimit) * 100;





    return (


     <div className="flex flex-col h-screen bg-offWhite-50">
            
            
    <div className="bg-slateBlue-50 p-6">
        <h2 className="text-3xl font-bold text-darkSlateGray-800 mb-2">Dashboard</h2>
        <p className="text-sm text-lightGray-300">Go Back to <Link to="/product" className="text-blue-500 hover:text-blue-600">Create</Link></p>
        <p>Plan usage:</p>
        <div className="relative w-full h-2 bg-gray-200 rounded overflow-hidden">
            <div className="absolute top-0 left-0 h-full bg-teal-400" style={{ width: `${progress}%` }}></div>
        </div>

    </div>

     

    <div className="flex flex-wrap justify-center gap-4 p-4 overflow-auto">
        <div className="max-w-md w-full bg-white shadow-lg rounded-lg border border-lightGray-300 p-6">
            <h2 className="text-xl font-bold text-darkSlateGray-800 mb-4">User Information</h2>
            {session && (
                
                <div>
                    
                    
                    <p className="text-darkSlateGray-800"><strong>Email:</strong> {session.user.email}</p>
                    
                    </div>
            )}

        </div>

        <div className="max-w-md w-full bg-white shadow-lg rounded-lg border border-lightGray-300 p-6">
            <h2 className="text-xl font-bold text-darkSlateGray-800 mb-4">Description Statistics</h2>
            <p className="text-darkSlateGray-800"><strong>Plan Limit:</strong> {planLimit}</p>
            <p className="text-darkSlateGray-800"><strong>Descriptions Generated:</strong> {generatedDescriptionCount}</p>
            <p className="text-darkSlateGray-800"><strong>Descriptions Left:</strong> {descriptionsLeft}</p>
            <p className="text-darkSlateGray-800"><strong>Total Words Generated:</strong> {totalWordsGenerated}</p>
        </div>


           <Feedback/>

        <div className="max-w-md w-full bg-white shadow-lg rounded-lg border border-lightGray-300 p-6">
            <h2 className="text-xl font-bold text-darkSlateGray-800 mb-4">Upgrade Your Plan</h2>
            <p className="text-darkSlateGray-800 mb-4">Your current plan is <strong>{userPlan && userPlan.subscription_status}</strong>.</p>
             
            <p className="text-darkSlateGray-800 mb-4">Get access to more features and higher limits by upgrading your plan.</p>
            <button className="w-full bg-teal-400 text-white py-2 px-4 rounded hover:bg-teal-500 transition duration-300">Upgrade Now</button>
        </div>
        <div className="max-w-md w-full bg-white shadow-lg rounded-lg border border-lightGray-300 p-6">
    <h2 className="text-xl font-bold text-darkSlateGray-800 mb-4">Manage Subscription</h2>

    <p className="text-darkSlateGray-800 mb-6">If you need to end your subscription, you can do so here. We're sorry to see you go!</p>
    <button className="w-full bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600 transition duration-300">Cancel Subscription</button>
</div>

 </div>

    



               

</div>

    );
}

export default UserDashboard;
