import React from 'react'
import { FiUsers, FiActivity, FiPlusCircle, FiTrendingUp, FiMessageSquare, FiStar, FiCheckCircle } from 'react-icons/fi'



function Experiment() {
    return (
        <div className="min-h-screen bg-gradient-to-br from-blue-50 to-indigo-100 p-8">
            <div className="max-w-7xl mx-auto">
                <h1 className="text-4xl font-extrabold text-indigo-900 mb-8">Dashboard</h1>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                    {/* Stats Cards */}
                    <div className="bg-white rounded-xl shadow-lg p-6 transition duration-300 hover:shadow-xl">
                        <div className="flex items-center justify-between mb-4">
                            <h2 className="text-xl font-semibold text-indigo-800">Total Users</h2>
                            <FiUsers className="text-3xl text-indigo-500" />
                        </div>
                        <p className="text-4xl font-bold text-indigo-600">1,234</p>
                        <p className="text-sm text-indigo-400 mt-2">+5.2% from last week</p>
                    </div>
                    
                    <div className="bg-white rounded-xl shadow-lg p-6 transition duration-300 hover:shadow-xl">
                        <div className="flex items-center justify-between mb-4">
                            <h2 className="text-xl font-semibold text-indigo-800">Active Projects</h2>
                            <FiActivity className="text-3xl text-indigo-500" />
                        </div>
                        <p className="text-4xl font-bold text-indigo-600">42</p>
                        <p className="text-sm text-indigo-400 mt-2">3 new this month</p>
                    </div>
                    
                    <div className="bg-white rounded-xl shadow-lg p-6 transition duration-300 hover:shadow-xl">
                        <div className="flex items-center justify-between mb-4">
                            <h2 className="text-xl font-semibold text-indigo-800">Revenue</h2>
                            <FiTrendingUp className="text-3xl text-indigo-500" />
                        </div>
                        <p className="text-4xl font-bold text-indigo-600">$89,241</p>
                        <p className="text-sm text-indigo-400 mt-2">+12.5% from last month</p>
                    </div>

                    {/* Recent Activity Card */}
                    <div className="bg-white rounded-xl shadow-lg p-6 col-span-1 md:col-span-2 transition duration-300 hover:shadow-xl">
                        <div className="flex items-center justify-between mb-6">
                            <h2 className="text-2xl font-semibold text-indigo-800">Recent Activity</h2>
                            <FiMessageSquare className="text-3xl text-indigo-500" />
                        </div>
                        <ul className="space-y-4">
                            <li className="flex items-center text-indigo-700">
                                <FiStar className="mr-3 text-yellow-400" />
                                <span>New user <strong>John Doe</strong> signed up</span>
                            </li>
                            <li className="flex items-center text-indigo-700">
                                <FiStar className="mr-3 text-yellow-400" />
                                <span>Project <strong>"Nebula"</strong> reached milestone</span>
                            </li>
                            <li className="flex items-center text-indigo-700">
                                <FiStar className="mr-3 text-yellow-400" />
                                <span>New feature <strong>"AI Chat"</strong> deployed</span>
                            </li>
                        </ul>
                    </div>
                    
                    {/* Quick Actions Card */}
                    <div className="bg-gradient-to-r from-indigo-500 to-purple-600 rounded-xl shadow-lg p-6 text-white transition duration-300 hover:shadow-xl">
                        <div className="flex items-center justify-between mb-6">
                            <h2 className="text-2xl font-semibold">Quick Actions</h2>
                            <FiPlusCircle className="text-3xl" />
                        </div>
                        <div className="space-y-4">
                            <button className="w-full bg-white text-indigo-600 font-bold py-2 px-4 rounded-lg transition duration-300 hover:bg-opacity-90">
                                Create New Project
                            </button>
                            <button className="w-full bg-indigo-400 bg-opacity-50 font-bold py-2 px-4 rounded-lg transition duration-300 hover:bg-opacity-75">
                                Generate Report
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* New Cards */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-6">
                {/* User Engagement Card */}
                <div className="bg-white rounded-xl shadow-lg p-6 transition duration-300 hover:shadow-xl">
                    <div className="flex items-center justify-between mb-4">
                        <h2 className="text-xl font-semibold text-indigo-800">User Engagement</h2>
                        <FiUsers className="text-3xl text-indigo-500" />
                    </div>
                    <p className="text-4xl font-bold text-indigo-600">78%</p>
                    <p className="text-sm text-indigo-400 mt-2">+5% from last week</p>
                </div>

                {/* Task Completion Card */}
                <div className="bg-white rounded-xl shadow-lg p-6 transition duration-300 hover:shadow-xl">
                    <div className="flex items-center justify-between mb-4">
                        <h2 className="text-xl font-semibold text-indigo-800">Task Completion</h2>
                        <FiCheckCircle className="text-3xl text-indigo-500" />
                    </div>
                    <p className="text-4xl font-bold text-indigo-600">92%</p>
                    <p className="text-sm text-indigo-400 mt-2">15 tasks completed today</p>
                </div>

                {/* System Health Card */}
                <div className="bg-white rounded-xl shadow-lg p-6 transition duration-300 hover:shadow-xl">
                    <div className="flex items-center justify-between mb-4">
                        <h2 className="text-xl font-semibold text-indigo-800">System Health</h2>
                        <FiActivity className="text-3xl text-indigo-500" />
                    </div>
                    <p className="text-4xl font-bold text-green-500">Excellent</p>
                    <p className="text-sm text-indigo-400 mt-2">All systems operational</p>
                </div>
            </div>
        </div>

        
    )
}

export default Experiment