import React, { useState } from 'react';
import { motion } from 'framer-motion';
import GeneratedOutput from './GeneratedOutput';
import axios from 'axios';

function SocialMediaPostGenerator() {
    const [platform, setPlatform] = useState('');
    const [topic, setTopic] = useState('');
    const [tone, setTone] = useState('');
    const [generatedPost, setGeneratedPost] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [copied, setCopied] = useState(false);
    const apiUrl = process.env.REACT_APP_API_URL;

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');
        setGeneratedPost('');

        try {
            const response = await axios.post(`${apiUrl}/generate-social-media-post`, {
                platform,
                topic,
                tone,
            });

            console.log('API Response:', response.data);

            if (response.data && response.data.social_media_post) {
                setGeneratedPost(response.data.social_media_post);
            } else if (response.data && response.data.error) {
                setError(response.data.error);
            } else {
                setError('No post generated. Please try again.');
            }
        } catch (error) {
            console.error('Error generating social media post:', error);
            setError(error.response?.data?.error || 'Failed to generate social media post. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const handleCopyToClipboard = () => {
        navigator.clipboard.writeText(generatedPost);
        setCopied(true);
        setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
    };

    return (
        <div className="max-w-4xl mx-auto p-4">
            <h2 className="text-2xl font-bold mb-4">Social Media Post Generator</h2>
            <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                    <label className="block mb-1">Platform:</label>
                    <select
                        value={platform}
                        onChange={(e) => setPlatform(e.target.value)}
                        className="w-full p-2 border rounded"
                        required
                    >
                        <option value="">Select Platform</option>
                        <option value="Facebook">Facebook</option>
                        <option value="Twitter">Twitter</option>
                        <option value="Instagram">Instagram</option>
                        <option value="LinkedIn">LinkedIn</option>
                    </select>
                </div>
                <div>
                    <label className="block mb-1">Topic:</label>
                    <input
                        type="text"
                        value={topic}
                        onChange={(e) => setTopic(e.target.value)}
                        className="w-full p-2 border rounded"
                        required
                    />
                </div>
                <div>
                    <label className="block mb-1">Tone:</label>
                    <select
                        value={tone}
                        onChange={(e) => setTone(e.target.value)}
                        className="w-full p-2 border rounded"
                        required
                    >
                        <option value="">Select Tone</option>
                        <option value="professional">Professional</option>
                        <option value="friendly">Friendly</option>
                        <option value="humorous">Humorous</option>
                        <option value="persuasive">Persuasive</option>
                    </select>
                </div>
                <button
                    type="submit"
                    className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700"
                    disabled={loading}
                >
                    {loading ? 'Generating...' : 'Generate Post'}
                </button>
            </form>
            {error && (
                <div className="mt-4 text-red-600">
                    {error}
                </div>
            )}
            {generatedPost && (
                <div className="mt-8">
                    <button 
                        className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700"
                        onClick={handleCopyToClipboard}
                    >
                        {copied ? 'Copied!' : 'Copy to Clipboard'}
                    </button>
                    <motion.div 
                        className="mt-8"
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                    >
                        <GeneratedOutput output={generatedPost} isLoading={loading} />
                    </motion.div>
                </div>
            )}
        </div>
    );
}

export default SocialMediaPostGenerator;