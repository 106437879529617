import React, { useContext } from 'react'
import supabase from './supabase/supabaseClient'
import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { SessionContext } from './sessionProvider'

// # Signup Component

// The `Signup` component is a React component that provides an interface for users to sign up for an account.

// ## Props

// This component does not accept any props.

// ## State

// This component maintains the following state:

// - `email`: The email entered by the user.
// - `password`: The password entered by the user.
// - `signupError`: Any error messages that occur during the signup process.
// - `session`: The current user session.

// ## Methods

// This component defines the following methods:

// - `handleSignup`: Handles the form submission, signs up the user with the entered email and password, and navigates to the verification page if the signup is successful.
// - `handleGoogleSignUp`: Initiates the Google sign-up process by redirecting the user to the Google authentication page.

// ## Rendered JSX Elements

// This component renders a form for the user to enter their email and password and a button to submit the form. If there's an error during the signup process, it displays the error message. Additionally, it includes a "Sign up with Google" button that initiates the Google sign-up process.

// ## External Dependencies

// This component uses the following external libraries:

// - `react`: For creating the component and managing state and effects.
// - `supabase`: For signing up the user and managing the user session.
// - `react-router-dom`: For navigation and routing.

function Signup() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [signupError, setSignupError] = useState('')
  const { session } = useContext(SessionContext)
  const [isSignupAttempted, setIsSignupAttempted] = useState(false)

  const navigate = useNavigate()

  const handleSignup = async (event) => {



    event.preventDefault()
    if (isSignupAttempted) return
    setIsSignupAttempted(true)
    setSignupError('')

    if (email === '' || password === '') {
      setSignupError('Please enter an email and password')
      setIsSignupAttempted(false)
      return
    }

    if (password.length < 8) {
      setSignupError('Password must be at least 8 characters long')
      setIsSignupAttempted(false)
      return
    }

    const { user, error } = await supabase.auth.signUp({ email, password })
    if (error) {
      console.error('Error signing up:', error.message)
      setSignupError(error.message)
    } else if (user) {
      console.log('User signed up successfully')
      navigate('/verify')
    }

    setIsSignupAttempted(false)
  }

  const handleGoogleSignUp = async () => {
    try {
      const { data, error } = await supabase.auth.signInWithOAuth({
        provider: 'google',
      })

      if (error) {
        throw error
      }

      // The user will be redirected to Google for authentication.
      // After successful authentication, they will be redirected back to your app.
    } catch (error) {
      console.error('Error signing up with Google:', error.message)
      setSignupError(error.message)
    }
  }

  if (session) {
    navigate('/')
  }

  return (
    <div className='flex justify-center items-center h-screen bg-gradient-to-b from-blue-50 to-indigo-100'>
      <div className="w-full max-w-md bg-white rounded-lg shadow-lg p-8">
        <h1 className="text-3xl font-bold text-center text-darkSlateGray-800 mb-6">Create Your Account</h1>
        <form onSubmit={handleSignup} className="space-y-6">
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-darkSlateGray-700">Email:</label>
            <input
              id="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="border border-gray-300 focus:border-blue-500 focus:ring-blue-500 rounded-md p-2 w-full mt-1"
            />
          </div>
          <div>
            <label htmlFor="password" className="block text-sm font-medium text-darkSlateGray-700">Password:</label>
            <input
              id="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="border border-gray-300 focus:border-blue-500 focus:ring-blue-500 rounded-md p-2 w-full mt-1"
            />
          </div>
          <button
            className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 rounded transition duration-300"
            type="submit"
            disabled={isSignupAttempted}
          >
            Sign Up
          </button>
        </form>
        <div className="mt-4">
          <button
            onClick={handleGoogleSignUp}
            className="w-full bg-white border border-gray-300 text-darkSlateGray-700 font-bold py-2 rounded flex items-center justify-center hover:bg-gray-100 transition duration-300"
          >
            <img src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg" alt="Google logo" className="w-5 h-5 mr-2" />
            Sign up with Google
          </button>
        </div>
        <p className="text-sm text-darkSlateGray-700 mt-4 text-center">Already have an account? <Link to="/signin" className="text-blue-600 hover:text-blue-700">Sign In</Link></p>
        {signupError && <p className="text-red-500 text-center mt-2">{signupError}</p>}
      </div>
    </div>
  )
}

export default Signup