import React from 'react';
import { Link } from 'react-router-dom';

function TermsOfService() {
  return (
    <div className="min-h-screen bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto">
        <Link to="/" className="text-blue-600 hover:text-blue-800 mb-8 inline-block">&larr; Back to Home</Link>
        <h1 className="text-3xl font-bold mb-6">Terms of Service</h1>
        
        <p className="mb-4">Welcome to QuikDescrip. By using our website and services, you agree to comply with and be bound by the following terms and conditions:</p>
        
        <h2 className="text-2xl font-semibold mt-6 mb-3">1. Acceptance of Terms</h2>
        <p className="mb-4">By accessing or using QuikDescrip, you agree to be bound by these Terms of Service and all applicable laws and regulations. If you do not agree with any part of these terms, you may not use our services.</p>
        
        <h2 className="text-2xl font-semibold mt-6 mb-3">2. Use of Services</h2>
        <p className="mb-4">You agree to use QuikDescrip only for lawful purposes and in a way that does not infringe the rights of, restrict or inhibit anyone else's use and enjoyment of the website.</p>
        
        <h2 className="text-2xl font-semibold mt-6 mb-3">3. User Accounts</h2>
        <p className="mb-4">To access certain features of QuikDescrip, you may be required to create an account. You are responsible for maintaining the confidentiality of your account and password and for restricting access to your computer.</p>
        
        <h2 className="text-2xl font-semibold mt-6 mb-3">4. Intellectual Property</h2>
        <p className="mb-4">The content, organization, graphics, design, compilation, magnetic translation, digital conversion and other matters related to QuikDescrip are protected under applicable copyrights, trademarks and other proprietary rights.</p>
        
        <h2 className="text-2xl font-semibold mt-6 mb-3">5. Limitation of Liability</h2>
        <p className="mb-4">QuikDescrip shall not be liable for any indirect, incidental, special, consequential or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses.</p>
        
        <h2 className="text-2xl font-semibold mt-6 mb-3">6. Modifications to Terms</h2>
        <p className="mb-4">We reserve the right to modify these Terms of Service at any time. We will notify users of any changes by posting the new Terms of Service on this page.</p>
        
        
        <p className="mt-8">Last updated: {new Date().toLocaleDateString()}</p>
      </div>
    </div>
  );
}

export default TermsOfService;