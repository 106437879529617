import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Blog from './blog';


function BlogPost() {
    const { postId } = useParams();
    const [post, setPost] = useState(null);

    useEffect(() => {
        fetch(`https://gentle-fortress-20969-76f4ba49f7d9.herokuapp.com/blog/${postId}`)
            .then(response => response.json())
            .then(data => setPost(data));
    }, [postId]);

    if (post === null) {
        return <div>Loading...</div>;
    }

    return (
        <div className="bg-lightGray-50 min-h-screen">
    <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <article>
            <h1 className="text-4xl font-extrabold text-darkSlateGray-800 mb-6">{post.title}</h1>
            <p className="text-gray-600 whitespace-pre-line mb-6">{post.content}</p>
            {/* Additional content like images, videos, etc. */}
        </article>
        <Link to="/blog">
            <button className="mt-8 bg-gradient-to-r from-teal-400 to-blue-500 hover:from-teal-500 hover:to-blue-600 text-white px-6 py-3 rounded-full font-bold text-lg transition duration-300">
                Back to Blog
            </button>
        </Link>
    </div>
</div>
    );
}

export default BlogPost;