import React from "react";
import { Link } from "react-router-dom";
import Blog from "./components/blog";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsOfService from "./components/TermsOfService";



function Footer() {
    return (
      <footer className="bg-offWhite-50 border-t border-lightGray-300">
      <div className="max-w-6xl mx-auto px-4 py-10">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 text-darkSlateGray-800">
          
          {/* Column 1 */}
          <div>
            <h4 className="font-bold mb-2 text-darkSlateGray-700">QuikDescrip</h4>
            <p>Transform your e-commerce with AI-powered product descriptions.</p>
          </div>
    
          {/* Column 2 */}
          <div>
            <h4 className="font-bold mb-2 text-darkSlateGray-700">Quick Links</h4>
            <ul>
              <li><a href="#" className="hover:text-blue-500 transition duration-300">Home</a></li>

              <Link to="/blog"> <li><a href="#" className="hover:text-blue-500 transition duration-300">Blog</a></li></Link>
              


              <li><a href="#" className="hover:text-blue-500 transition duration-300">Features</a></li>
              <li><a href="#" className="hover:text-blue-500 transition duration-300">Pricing</a></li>
              <li><a href="#" className="hover:text-blue-500 transition duration-300">FAQs</a></li>
            </ul>
          </div>
    
          {/* Column 3 */}
          <div>
            <h4 className="font-bold mb-2 text-darkSlateGray-700">Resources</h4>
            <ul>
              <Link to="/privacy-policy" className="hover:text-blue-500 transition duration-300">Privacy Policy</Link>
              <Link to="/terms-of-service" className="hover:text-blue-500 transition duration-300">Terms of Service</Link>
                </ul>
          </div>
    
        </div>
    
        <div className="text-center mt-8 text-gray-500 text-sm">
          &copy; {new Date().getFullYear()} QuikDescrip.com All rights reserved.
        </div>
      </div>
    </footer>
    

    );
  }
  
  export default Footer;
  
  