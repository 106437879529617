import React, { useState } from "react";
import { Link } from 'react-router-dom';
import axios from 'axios';

function BlogPostGenerator() {
    const [title, setTitle] = useState("");
    const [generatedContent, setGeneratedContent] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);

    const apiUrl = process.env.REACT_APP_API_URL;

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');

        try {
            const response = await axios.post(`${apiUrl}/blogpost_generater`, {
                prompt: title
            });
            setGeneratedContent(response.data.blogpost);
        } catch (err) {
            setError('Failed to generate blog post. Please try again.');
            console.error(err);
        }

        setLoading(false);
    }

    return (
        <div className="max-w-4xl mx-auto">
            <h2 className="text-2xl font-bold mb-4">Blog Post Generator</h2>
            <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                    <label className="block mb-1">Blog Post Title:</label>
                    <input
                        type="text"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        className="w-full p-2 border rounded"
                        required
                    />
                </div>
                <button
                    type="submit"
                    className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700"
                    disabled={loading}
                >
                    {loading ? 'Generating...' : 'Generate Blog Post'}
                </button>
            </form>
            {error && (
                <div className="mt-4 text-red-600">
                    {error}
                </div>
            )}
            {generatedContent && (
                <div className="mt-8">
                    <h3 className="text-xl font-bold mb-2">Generated Blog Post:</h3>
                    <div className="bg-gray-100 p-4 rounded whitespace-pre-wrap">
                        {generatedContent}
                    </div>
                </div>
            )}
        </div>
    );
}

export default BlogPostGenerator;