import React, { useState } from 'react';
import axios from 'axios';

function CaseStudyTemplate() {
  const [companyName, setCompanyName] = useState('');
  const [industry, setIndustry] = useState('');
  const [challenge, setChallenge] = useState('');
  const [solution, setSolution] = useState('');
  const [results, setResults] = useState('');
  const [caseStudy, setCaseStudy] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const apiUrl = process.env.REACT_APP_API_URL;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);
    setCaseStudy('');
    try {
      const response = await axios.post(`${apiUrl}/generate-case-study`, {
        companyName,
        industry,
        challenge,
        solution,
        results
      });
      if (response.data.caseStudy) {
        setCaseStudy(response.data.caseStudy);
      } else if (response.data.error) {
        throw new Error(response.data.error);
      } else {
        throw new Error('Invalid response format');
      }
    } catch (error) {
      console.error('Error generating case study:', error);
      setError(error.response?.data?.error || error.message || 'An error occurred while generating the case study. Please try again.');
    }
    setIsLoading(false);
  };

  return (
    <div className="max-w-4xl mx-auto">
      <h2 className="text-2xl font-bold mb-4">Case Study Template Generator</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block mb-1">Company Name:</label>
          <input
            type="text"
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
            className="w-full p-2 border rounded"
            required
          />
        </div>
        <div>
          <label className="block mb-1">Industry:</label>
          <input
            type="text"
            value={industry}
            onChange={(e) => setIndustry(e.target.value)}
            className="w-full p-2 border rounded"
            required
          />
        </div>
        <div>
          <label className="block mb-1">Challenge:</label>
          <textarea
            value={challenge}
            onChange={(e) => setChallenge(e.target.value)}
            className="w-full p-2 border rounded"
            rows="3"
            required
          ></textarea>
        </div>
        <div>
          <label className="block mb-1">Solution:</label>
          <textarea
            value={solution}
            onChange={(e) => setSolution(e.target.value)}
            className="w-full p-2 border rounded"
            rows="3"
            required
          ></textarea>
        </div>
        <div>
          <label className="block mb-1">Results:</label>
          <textarea
            value={results}
            onChange={(e) => setResults(e.target.value)}
            className="w-full p-2 border rounded"
            rows="3"
            required
          ></textarea>
        </div>
        <button
          type="submit"
          className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700"
          disabled={isLoading}
        >
          {isLoading ? 'Generating...' : 'Generate Case Study'}
        </button>
      </form>
      {error && (
        <div className="mt-4 text-red-600">
          {error}
        </div>
      )}
      {caseStudy && (
        <div className="mt-8">
          <h3 className="text-xl font-bold mb-2">Generated Case Study:</h3>
          <div className="bg-gray-100 p-4 rounded whitespace-pre-wrap">
            {caseStudy}
          </div>
        </div>
      )}
    </div>
  );
}

export default CaseStudyTemplate;