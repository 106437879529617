// supabaseClient.js
import { createClient } from '@supabase/supabase-js'

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL
const anonKey = process.env.REACT_APP_SUPABASE_ANON_KEY

// Ensure both the Supabase URL and Anon Key are provided
if (!supabaseUrl || !anonKey) {
  throw new Error('Supabase URL and Anon Key must be set in environment variables')
}

const supabase = createClient(supabaseUrl, anonKey)





export default supabase
