import React, { useState } from 'react';
import axios from 'axios';

function HashtagGenerator() {
  const [topic, setTopic] = useState('');
  const [platform, setPlatform] = useState('instagram');
  const [numHashtags, setNumHashtags] = useState(5);
  const [hashtags, setHashtags] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const apiUrl = process.env.REACT_APP_API_URL;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);
    setHashtags([]);
    try {
      const response = await axios.post(`${apiUrl}/generate-hashtags`, {
        topic,
        platform,
        numHashtags
      });
      if (response.data.hashtags && Array.isArray(response.data.hashtags)) {
        setHashtags(response.data.hashtags);
      } else if (response.data.error) {
        throw new Error(response.data.error);
      } else {
        throw new Error('Invalid response format');
      }
    } catch (error) {
      console.error('Error generating hashtags:', error);
      setError(error.response?.data?.error || error.message || 'An error occurred while generating hashtags. Please try again.');
    }
    setIsLoading(false);
  };

  return (
    <div className="max-w-2xl mx-auto">
      <h2 className="text-2xl font-bold mb-4">Hashtag Generator</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block mb-1">Topic:</label>
          <input
            type="text"
            value={topic}
            onChange={(e) => setTopic(e.target.value)}
            className="w-full p-2 border rounded"
            required
          />
        </div>
        <div>
          <label className="block mb-1">Platform:</label>
          <select
            value={platform}
            onChange={(e) => setPlatform(e.target.value)}
            className="w-full p-2 border rounded"
          >
            <option value="instagram">Instagram</option>
            <option value="twitter">Twitter</option>
            <option value="tiktok">TikTok</option>
            <option value="linkedin">LinkedIn</option>
          </select>
        </div>
        <div>
          <label className="block mb-1">Number of Hashtags:</label>
          <input
            type="number"
            value={numHashtags}
            onChange={(e) => setNumHashtags(parseInt(e.target.value))}
            min="1"
            max="30"
            className="w-full p-2 border rounded"
          />
        </div>
        <button
          type="submit"
          className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700"
          disabled={isLoading}
        >
          {isLoading ? 'Generating...' : 'Generate Hashtags'}
        </button>
      </form>
      {error && (
        <div className="mt-4 text-red-600">
          {error}
        </div>
      )}
      {hashtags.length > 0 && (
        <div className="mt-8">
          <h3 className="text-xl font-bold mb-2">Generated Hashtags:</h3>
          <div className="bg-gray-100 p-4 rounded">
            {hashtags.map((hashtag, index) => (
              <span key={index} className="inline-block bg-blue-200 rounded-full px-3 py-1 text-sm font-semibold text-blue-700 mr-2 mb-2">
                {hashtag}
              </span>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default HashtagGenerator;