import React, { useState } from 'react';
import axios from 'axios';

function VideoScriptGenerator() {
  const [topic, setTopic] = useState('');
  const [videoType, setVideoType] = useState('explainer');
  const [duration, setDuration] = useState('60');
  const [targetAudience, setTargetAudience] = useState('');
  const [script, setScript] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const apiUrl = process.env.REACT_APP_API_URL;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);
    setScript('');
    try {
      const response = await axios.post(`${apiUrl}/generate-video-script`, {
        topic,
        videoType,
        duration,
        targetAudience
      });
      if (response.data.script) {
        setScript(response.data.script);
      } else if (response.data.error) {
        throw new Error(response.data.error);
      } else {
        throw new Error('Invalid response format');
      }
    } catch (error) {
      console.error('Error generating video script:', error);
      setError(error.response?.data?.error || error.message || 'An error occurred while generating the video script. Please try again.');
    }
    setIsLoading(false);
  };

  return (
    <div className="max-w-4xl mx-auto p-8 bg-white shadow-lg rounded-lg">
      <h2 className="text-4xl font-bold mb-8 text-center text-gray-800">Video Script Generator</h2>
      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <label className="block mb-2 text-lg font-medium text-gray-700">Topic:</label>
          <input
            type="text"
            value={topic}
            onChange={(e) => setTopic(e.target.value)}
            className="w-full p-4 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            required
          />
        </div>
        <div>
          <label className="block mb-2 text-lg font-medium text-gray-700">Video Type:</label>
          <select
            value={videoType}
            onChange={(e) => setVideoType(e.target.value)}
            className="w-full p-4 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          >
            <option value="explainer">Explainer Video</option>
            <option value="product-demo">Product Demo</option>
            <option value="tutorial">Tutorial</option>
            <option value="social-media">Social Media Video</option>
          </select>
        </div>
        <div>
          <label className="block mb-2 text-lg font-medium text-gray-700">Duration (in seconds):</label>
          <input
            type="number"
            value={duration}
            onChange={(e) => setDuration(e.target.value)}
            className="w-full p-4 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            min="30"
            max="300"
            required
          />
        </div>
        <div>
          <label className="block mb-2 text-lg font-medium text-gray-700">Target Audience:</label>
          <input
            type="text"
            value={targetAudience}
            onChange={(e) => setTargetAudience(e.target.value)}
            className="w-full p-4 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            required
          />
        </div>
        <button
          type="submit"
          className="w-full bg-gradient-to-r from-blue-500 to-purple-600 text-white py-3 rounded-lg hover:from-blue-600 hover:to-purple-700 transition duration-300 ease-in-out flex items-center justify-center disabled:opacity-50"
          disabled={isLoading}
        >
          {isLoading ? (
            <>
              <svg className="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
              </svg>
              Generating...
            </>
          ) : (
            'Generate Video Script'
          )}
        </button>
      </form>
      {error && (
        <div className="mt-4 text-red-600 text-center">
          {error}
        </div>
      )}
      {script && (
        <div className="mt-8 bg-gray-100 p-6 rounded-lg shadow-inner">
          <h3 className="text-2xl font-semibold mb-4 text-gray-800">Generated Video Script:</h3>
          <div className="whitespace-pre-wrap">
            {script}
          </div>
        </div>
      )}
    </div>
  );
}

export default VideoScriptGenerator;