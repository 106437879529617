
import React from 'react';
import { Link } from 'react-router-dom';
const Failure = () => {
    return (
        <div className="flex flex-col items-center justify-center h-screen">
            <h1 className="text-4xl font-bold mb-4 text-red-500">Payment Failed!</h1>
            <p className="text-lg">Please try again later.</p>
            <Link className="link-style text-blue-500 hover:text-blue-700 mt-4" to="/">Return to home</Link>
            
        </div>
    );
}

export default Failure;
