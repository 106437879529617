import React, { useState } from 'react';
import axios from 'axios';

function SocialMediaPoll() {
  const [topic, setTopic] = useState('');
  const [platform, setPlatform] = useState('twitter');
  const [numOptions, setNumOptions] = useState(2);
  const [poll, setPoll] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const apiUrl = process.env.REACT_APP_API_URL;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);
    setPoll(null);
    try {
      const response = await axios.post(`${apiUrl}/generate-social-media-poll`, {
        topic,
        platform,
        numOptions
      });
      if (response.data.poll) {
        setPoll(response.data.poll);
      } else if (response.data.error) {
        throw new Error(response.data.error);
      } else {
        throw new Error('Invalid response format');
      }
    } catch (error) {
      console.error('Error generating social media poll:', error);
      setError(error.response?.data?.error || error.message || 'An error occurred while generating the poll. Please try again.');
    }
    setIsLoading(false);
  };

  return (
    <div className="max-w-4xl mx-auto">
      <h2 className="text-2xl font-bold mb-4">Social Media Poll Generator</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block mb-1">Topic:</label>
          <input
            type="text"
            value={topic}
            onChange={(e) => setTopic(e.target.value)}
            className="w-full p-2 border rounded"
            required
          />
        </div>
        <div>
          <label className="block mb-1">Platform:</label>
          <select
            value={platform}
            onChange={(e) => setPlatform(e.target.value)}
            className="w-full p-2 border rounded"
          >
            <option value="twitter">Twitter</option>
            <option value="facebook">Facebook</option>
            <option value="instagram">Instagram</option>
            <option value="linkedin">LinkedIn</option>
          </select>
        </div>
        <div>
          <label className="block mb-1">Number of Options:</label>
          <input
            type="number"
            value={numOptions}
            onChange={(e) => setNumOptions(parseInt(e.target.value))}
            min="2"
            max="4"
            className="w-full p-2 border rounded"
            required
          />
        </div>
        <button
          type="submit"
          className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700"
          disabled={isLoading}
        >
          {isLoading ? 'Generating...' : 'Generate Poll'}
        </button>
      </form>
      {error && (
        <div className="mt-4 text-red-600">
          {error}
        </div>
      )}
      {poll && (
        <div className="mt-8">
          <h3 className="text-xl font-bold mb-2">Generated Social Media Poll:</h3>
          <div className="bg-gray-100 p-4 rounded">
            <p className="font-bold mb-2">{poll.question}</p>
            <ul className="list-disc pl-5">
              {poll.options.map((option, index) => (
                <li key={index}>{option}</li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
}

export default SocialMediaPoll;