import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter as Router, Route,Routes } from 'react-router-dom';
import { SessionProvider } from './sessionProvider';
import Dashboard  from './dashboard/Dashboard';
import ReactGA from 'react-ga';


ReactGA.initialize('G-80RNZKKT2Y');
ReactGA.pageview(window.location.pathname + window.location.search);



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
   <SessionProvider>
      <App />

   </SessionProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
