import React, { useState } from 'react';
import axios from 'axios';

function SEOContentOptimizer() {
  const [content, setContent] = useState('');
  const [keywords, setKeywords] = useState('');
  const [optimizedContent, setOptimizedContent] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const apiUrl = process.env.REACT_APP_API_URL;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await axios.post(`${apiUrl}/optimize-seo-content`, {
        content,
        keywords
      });
      setOptimizedContent(response.data.optimized_content);
    } catch (error) {
      console.error('Error optimizing SEO content:', error);
    }
    setIsLoading(false);
  };

  return (
    <div className="max-w-2xl mx-auto">
      <h2 className="text-2xl font-bold mb-4">SEO Content Optimizer</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block mb-1">Content:</label>
          <textarea
            value={content}
            onChange={(e) => setContent(e.target.value)}
            className="w-full p-2 border rounded"
            rows="6"
            required
          ></textarea>
        </div>
        <div>
          <label className="block mb-1">Target Keywords (comma-separated):</label>
          <input
            type="text"
            value={keywords}
            onChange={(e) => setKeywords(e.target.value)}
            className="w-full p-2 border rounded"
            required
          />
        </div>
        <button
          type="submit"
          className="bg-yellow-500 text-white px-4 py-2 rounded hover:bg-yellow-600"
          disabled={isLoading}
        >
          {isLoading ? 'Optimizing...' : 'Optimize Content'}
        </button>
      </form>
      {optimizedContent && (
        <div className="mt-8">
          <h3 className="text-xl font-bold mb-2">Optimized Content:</h3>
          <div className="bg-gray-100 p-4 rounded whitespace-pre-wrap">{optimizedContent}</div>
        </div>
      )}
    </div>
  );
}

export default SEOContentOptimizer;