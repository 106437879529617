import React, { useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { SessionContext } from '../sessionProvider';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Loading from './loading';



const ImageGenerator = () => {
    const [productPrompt, setProductPrompt] = useState('');
    const [imagePath, setImagePath] = useState('');
    const [generatedImages, setGeneratedImages] = useState([]);
    const [progress, setProgress] = useState(0);
    const [loading, setLoading] = useState(false);
    const { session, setSession } = useContext(SessionContext);



    const navigate = useNavigate();


    if (!session) {
        navigate('/signin');
    }








    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setGeneratedImages([]);
        setProgress(0); // Reset progress on new submission

        try {
            const response = await axios.post(' http://127.0.0.1:5000/generateimage', {
                product_prompt: productPrompt,
                image_path: imagePath
            }, {
                onUploadProgress: progressEvent => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setProgress(percentCompleted);
                }
            });

            if (response.data && response.data.output) {
                setGeneratedImages(response.data.output);
            } else {
                console.error("Unexpected response format:", response);
                alert("Received unexpected response format from server.");
            }
        } catch (error) {
            console.error("There was an error generating the images:", error);
            alert("Failed to generate images.");
        } finally {
            setLoading(false); // Ensure loading is false after the operation
        }
    };

    return (

      <div className="flex flex-col md:flex-row h-screen bg-offWhite-50">
    {/* Left Column for Form */}
    <div className="md:flex md:w-1/2 justify-center items-center p-4">
    <div className="max-w-lg w-full mx-auto">
        <h1 className="text-2xl md:text-3xl font-bold mb-6 bg-gradient-to-r from-teal-400 to-blue-500 text-transparent bg-clip-text text-center">
            Generate Product Photoshots using AI
        </h1>
        <div className="bg-white p-8 rounded-lg shadow-lg">
            <form onSubmit={handleSubmit} className="flex flex-col space-y-4">
                <div>
                    <label htmlFor="productPrompt" className="block text-sm font-medium text-darkSlateGray-800">Product Prompt or Product name</label>
                    <input
                        id="productPrompt"
                        type="text"
                        value={productPrompt}
                        onChange={(e) => setProductPrompt(e.target.value)}
                        required
                        className="mt-1 p-3 block w-full border border-lightGray-300 rounded focus:ring-softSkyBlue-500 focus:border-softSkyBlue-500 transition duration-300"
                        aria-describedby="productPromptHelp"
                    />
                    <p id="productPromptHelp" className="text-sm text-gray-500">Please provide a prompt or name of the product you want to generate images for.</p>
                </div>
                <div>
                    <label htmlFor="imagePath" className="block text-sm font-medium text-darkSlateGray-800">Image URL:</label>
                    <input
                        id="imagePath"
                        type="text"
                        value={imagePath}
                        onChange={(e) => setImagePath(e.target.value)}
                        required
                        className="mt-1 p-3 block w-full border border-lightGray-300 rounded focus:ring-softSkyBlue-500 focus:border-softSkyBlue-500 transition duration-300"
                        aria-describedby="imagePathHelp"
                    />
                    <p id="imagePathHelp" className="text-sm text-gray-500">Please provide a URL of the image you want to use as a base for the product photoshots (currently accepts only URLs).</p>
                </div>
                <button type="submit" className="py-3 px-6 mb-4 text-lg text-offWhite-50 bg-gradient-to-r from-teal-400 to-blue-500 rounded-md">
                    Generate Images
                </button>
                <p className="text-sm text-gray-500">Generating your images involves a sophisticated AI model and may take up to 30 seconds. We appreciate your patience as we continually work on enhancing this process for faster results.</p>
            </form>
        </div>
    </div>
</div>


    {/* Right Column for Displaying Generated Images */}
    <div className="md:w-1/2 flex justify-center items-center bg-white overflow-auto">
    <div className="p-4 w-full max-w-md mx-auto">
        {loading ? (
            <div className="flex justify-center items-center h-full">
                <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-black"></div>
            </div>
        ) : generatedImages.length > 0 ? (
            <div>
                <h3 className="text-lg leading-6 font-medium text-darkSlateGray-800">Generated Images:</h3>
                <div className="mt-4 grid grid-cols-1 gap-4">
                    {generatedImages.map((image, index) => (
                        <img key={index} className="w-full h-auto object-cover rounded-lg shadow-lg" src={image} alt={`Generated ${index}`} />
                    ))}
                </div>
            </div>
        ) : (
            <div className="text-center text-darkSlateGray-800">No images generated yet.</div>
        )}
    </div>
</div>

</div>

  
    );
};

export default ImageGenerator;
