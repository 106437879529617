import React, { useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import GeneratedOutput from './GeneratedOutput';

function MarketingCopyGenerator() {
    const [productName, setProductName] = useState('');
    const [targetAudience, setTargetAudience] = useState('');
    const [keyFeatures, setKeyFeatures] = useState('');
    const [tone, setTone] = useState('');
    const [marketingCopy, setMarketingCopy] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const apiUrl = process.env.REACT_APP_API_URL;

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');

        try {
            const response = await axios.post(`${apiUrl}/generate-marketing-copy`, {
                product_name: productName,
                target_audience: targetAudience,
                key_features: keyFeatures,
                tone: tone
            });

            setMarketingCopy(response.data.marketing_copy);
        } catch (err) {
            setError('Failed to generate marketing copy. Please try again.');
            console.error(err);
        }

        setLoading(false);
    };

    return (
        <div className="max-w-2xl mx-auto">
            <h2 className="text-2xl font-bold mb-4">Marketing Copy Generator</h2>
            <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                    <label className="block mb-1">Product Name:</label>
                    <input
                        type="text"
                        value={productName}
                        onChange={(e) => setProductName(e.target.value)}
                        className="w-full p-2 border rounded"
                        required
                    />
                </div>
                <div>
                    <label className="block mb-1">Target Audience:</label>
                    <input
                        type="text"
                        value={targetAudience}
                        onChange={(e) => setTargetAudience(e.target.value)}
                        className="w-full p-2 border rounded"
                        required
                    />
                </div>
                <div>
                    <label className="block mb-1">Key Features:</label>
                    <textarea
                        value={keyFeatures}
                        onChange={(e) => setKeyFeatures(e.target.value)}
                        className="w-full p-2 border rounded"
                        required
                    />
                </div>
                <div>
                    <label className="block mb-1">Tone:</label>
                    <select
                        value={tone}
                        onChange={(e) => setTone(e.target.value)}
                        className="w-full p-2 border rounded"
                        required
                    >
                        <option value="">Select Tone</option>
                        <option value="professional">Professional</option>
                        <option value="friendly">Friendly</option>
                        <option value="humorous">Humorous</option>
                        <option value="persuasive">Persuasive</option>
                    </select>
                </div>
                <button
                    type="submit"
                    className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700"
                    disabled={loading}
                >
                    {loading ? 'Generating...' : 'Generate Marketing Copy'}
                </button>
            </form>
            {error && (
                <div className="mt-4 text-red-600">
                    {error}
                </div>
            )}
            {marketingCopy && (
                <div className="mt-8">
                    <h3 className="text-xl font-bold mb-2">Generated Marketing Copy:</h3>
                    <div className="bg-gray-100 p-4 rounded whitespace-pre-wrap">
                        {marketingCopy}
                    </div>
                </div>
            )}
        </div>
    );
}

export default MarketingCopyGenerator;