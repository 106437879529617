import React from 'react';
import { Link } from 'react-router-dom';

const Success = () => {
    return (
        <div>
            <div className="flex flex-col items-center justify-center h-screen bg-white rounded-lg shadow-lg p-8">
                <h1 className="text-4xl font-bold mb-4 text-green-500">Payment Successful!</h1>
                <p className="text-lg">Thank you for your purchase.</p>
                <Link className="link-style text-blue-500 hover:text-blue-700 mt-4" to="/product">Return to product</Link>
            </div>
        </div>
    );
};

export default Success;



