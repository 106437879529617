import React from 'react';

const DescriptionOutput = ({ isABTesting, descriptionA, descriptionB, description, onCopy }) => {
  return (
    <div className="mt-6">
      {isABTesting ? (
        <>
          <h2 className="text-xl font-bold mb-4">A/B Test Results</h2>
          <div className="grid grid-cols-2 gap-4">
            <div className="bg-white p-4 rounded shadow">
              <h3 className="font-bold mb-2">Version A</h3>
              <p>{descriptionA}</p>
              <button 
                onClick={() => onCopy(descriptionA)}
                className="mt-2 py-1 px-2 text-offWhite-50 bg-softSkyBlue-500 rounded"
              >
                Copy
              </button>
            </div>
            <div className="bg-white p-4 rounded shadow">
              <h3 className="font-bold mb-2">Version B</h3>
              <p>{descriptionB}</p>
              <button 
                onClick={() => onCopy(descriptionB)}
                className="mt-2 py-1 px-2 text-offWhite-50 bg-softSkyBlue-500 rounded"
              >
                Copy
              </button>
            </div>
          </div>
        </>
      ) : (
        <>
          <h2 className="text-xl font-bold mb-4">Generated Description</h2>
          <p>{description}</p>
          <button 
            onClick={() => onCopy(description)}
            className="mt-2 py-1 px-2 text-offWhite-50 bg-softSkyBlue-500 rounded"
          >
            Copy
          </button>
        </>
      )}
    </div>
  );
};

export default DescriptionOutput;
