import React, { useState } from 'react';

const DescriptionList = ({ descriptions, onCopy }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const filteredDescriptions = descriptions.filter(desc => 
    desc.generated_description.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="flex-grow p-4">
      <div className="flex items-center justify-center mb-6">
        <h2 className="text-2xl font-bold text-darkSlateGray-800">
          Generated Descriptions
        </h2>
      </div>

      <div className="mb-6">
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search generated descriptions"
          className="w-full p-3 text-darkSlateGray-800 bg-lightGray-50 border border-lightGray-300 rounded focus:ring-softSkyBlue-500 focus:border-softSkyBlue-500 transition duration-300"
        />
      </div>

      {filteredDescriptions.map((description, index) => (
        <div key={index} className="p-4 mb-4 text-darkSlateGray-800 bg-offWhite-50 rounded-lg flex flex-col md:flex-row justify-between items-center border-b border-lightGray-300">
          <p className="font-semibold mb-4 md:mb-0">
            <strong>Description {index + 1}:</strong> {description.generated_description}
          </p>
          <button 
            onClick={() => onCopy(description.generated_description)}
            className="py-2 px-4 text-offWhite-50 bg-softSkyBlue-500 rounded font-bold transition duration-300 hover:bg-vibrantCoral-500"
          >
            Copy
          </button>
        </div>
      ))}
    </div>
  );
};

export default DescriptionList;
