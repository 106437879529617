import React, { useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

function EmailCampaignGenerator() {
  const [campaignType, setCampaignType] = useState('');
  const [productService, setProductService] = useState('');
  const [targetAudience, setTargetAudience] = useState('');
  const [generatedCampaign, setGeneratedCampaign] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const apiUrl = process.env.REACT_APP_API_URL;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      const response = await axios.post(`${apiUrl}/generate-email-campaign`, {
        campaign_type: campaignType,
        product_service: productService,
        target_audience: targetAudience
      });
      setGeneratedCampaign(response.data.email_campaign);
    } catch (error) {
      setError('Failed to generate email campaign. Please try again.');
      console.error('Error generating email campaign:', error);
    }

    setLoading(false);
  };

  return (
    <div className="max-w-2xl mx-auto">
      <h2 className="text-2xl font-bold mb-4">Email Campaign Generator</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block mb-1">Campaign Type:</label>
          <select
            value={campaignType}
            onChange={(e) => setCampaignType(e.target.value)}
            className="w-full p-2 border rounded"
            required
          >
            <option value="">Select Campaign Type</option>
            <option value="welcome">Welcome</option>
            <option value="promotional">Promotional</option>
            <option value="newsletter">Newsletter</option>
            <option value="abandoned_cart">Abandoned Cart</option>
          </select>
        </div>
        <div>
          <label className="block mb-1">Product/Service:</label>
          <input
            type="text"
            value={productService}
            onChange={(e) => setProductService(e.target.value)}
            className="w-full p-2 border rounded"
            required
          />
        </div>
        <div>
          <label className="block mb-1">Target Audience:</label>
          <input
            type="text"
            value={targetAudience}
            onChange={(e) => setTargetAudience(e.target.value)}
            className="w-full p-2 border rounded"
            required
          />
        </div>
        <button
          type="submit"
          className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700"
          disabled={loading}
        >
          {loading ? 'Generating...' : 'Generate Campaign'}
        </button>
      </form>
      {error && (
        <div className="mt-4 text-red-600">
          {error}
        </div>
      )}
      {generatedCampaign && (
        <div className="mt-8">
          <h3 className="text-xl font-bold mb-2">Generated Campaign:</h3>
          <div className="bg-gray-100 p-4 rounded whitespace-pre-wrap">
            {generatedCampaign}
          </div>
        </div>
      )}
    </div>
  );
}

export default EmailCampaignGenerator;