import React, { useState } from 'react';
import axios from 'axios';

function ContentTranslator() {
  const [content, setContent] = useState('');
  const [sourceLanguage, setSourceLanguage] = useState('');
  const [targetLanguage, setTargetLanguage] = useState('');
  const [translatedContent, setTranslatedContent] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const apiUrl = process.env.REACT_APP_API_URL;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await axios.post(`${apiUrl}/translate-content`, {
        content,
        sourceLanguage,
        targetLanguage
      });
      setTranslatedContent(response.data.translatedContent);
    } catch (error) {
      console.error('Error translating content:', error);
    }
    setIsLoading(false);
  };

  return (
    <div className="max-w-2xl mx-auto">
      <h2 className="text-2xl font-bold mb-4">Content Translator</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block mb-1">Content to Translate:</label>
          <textarea
            value={content}
            onChange={(e) => setContent(e.target.value)}
            className="w-full p-2 border rounded"
            rows="6"
            required
          ></textarea>
        </div>
        <div>
          <label className="block mb-1">Source Language:</label>
          <input
            type="text"
            value={sourceLanguage}
            onChange={(e) => setSourceLanguage(e.target.value)}
            className="w-full p-2 border rounded"
            placeholder="e.g., English"
            required
          />
        </div>
        <div>
          <label className="block mb-1">Target Language:</label>
          <input
            type="text"
            value={targetLanguage}
            onChange={(e) => setTargetLanguage(e.target.value)}
            className="w-full p-2 border rounded"
            placeholder="e.g., Spanish"
            required
          />
        </div>
        <button
          type="submit"
          className="bg-green-600 text-white px-4 py-2 rounded hover:bg-green-700"
          disabled={isLoading}
        >
          {isLoading ? 'Translating...' : 'Translate Content'}
        </button>
      </form>
      {translatedContent && (
        <div className="mt-8">
          <h3 className="text-xl font-bold mb-2">Translated Content:</h3>
          <div className="bg-gray-100 p-4 rounded whitespace-pre-wrap">{translatedContent}</div>
        </div>
      )}
    </div>
  );
}

export default ContentTranslator;