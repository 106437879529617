import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

function Blog() {
    const [posts, setPosts] = useState([]);

    useEffect(() => {
        // Replace 'http://localhost:5000' with your Flask server's URL
        fetch('https://gentle-fortress-20969-76f4ba49f7d9.herokuapp.com/blog')
            .then(response => response.json())
            .then(data => setPosts(data));
    }, []);

    return (
        <div className="bg-lightGray-50 min-h-screen">
    <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <h2 className="text-4xl font-extrabold text-darkSlateGray-800 mb-12 text-center">Our Blog</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {posts.map((post, index) => (
                <div key={index} className="bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 p-6">
                    <Link to={`/blog/${post.title.replace(/\s+/g, '-').toLowerCase()}`}>
                        <h3 className="text-2xl font-semibold text-gray-800 mb-3 hover:text-blue-600 transition-colors duration-300">{post.title}</h3>
                    </Link>
                    <p className="text-gray-600 mb-4">{post.content.substring(0, 100)}...</p>
                    <Link to={`/blog/${post.title.replace(/\s+/g, '-').toLowerCase()}`} className="text-blue-600 hover:underline">
                        Read more
                    </Link>
                </div>
            ))}
        </div>
    </div>
</div>




    
    

    );
}

export default Blog;