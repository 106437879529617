import React from 'react';
import { 
  FaTwitter, FaBlog, FaEnvelope, FaBullhorn, FaSearch, 
  FaImage, FaPodcast, FaHeading, FaHashtag, FaCalendarAlt, 
  FaVideo, FaFilm, FaChartBar, FaAd, FaLanguage, 
  FaNewspaper, FaQuestion, FaFileAlt, FaPoll, FaRecycle
} from 'react-icons/fa';

// Import all the components
import SEOContentOptimizer from '../components/tools/SeoContentOptimizer';
import PodcastOutlineGenerator from '../components/tools/PodcastOutlineGenerator';
import InfographicPlanner from '../components/tools/InfographicPlanner';
import ContentTranslator from '../components/tools/ContentTranslator';
import HeadlineAnalyzer from '../components/tools/HeadlineAnalyzer';
import HashtagGenerator from '../components/tools/HashtagGenerator';
import ContentCalendar from '../components/tools/ContentCalendar';
import NewsletterTemplate from '../components/tools/NewsletterTemplate';
import PressRelease from '../components/tools/PressRelease';
import FaqGenerator from '../components/tools/FAQGenerator';
import CaseStudyTemplate from '../components/tools/CaseStudyTemplate';
import SocialMediaPoll from '../components/tools/SocialMediaPoll';
import AdCopyGenerator from '../components/tools/AdCopyGenerator';
import VideoScriptGenerator from '../components/tools/VideoScriptGenerator';
import SocialMediaPostGenerator from '../components/SocialMediaPostGenerator';
import BlogPostGenerator from '../components/blogpostgenerater';
import EmailCampaignGenerator from '../components/EmailCampaignGenerator';
import MarketingCopyGenerator from '../components/MarketingCopyGenerator';
import DescriptionGenerator from '../description';
import ImageGenerator from '../components/tools/imagegenerater';
import ContentRepurposingAssistant from '../components/ContentRepurposingAssistant';

export const toolCategories = [
  {
    id: 'content',
    name: 'Content Creation',
    tools: [
      { id: 'social', name: 'Social Media Post Generator', icon: <FaTwitter /> },
      { id: 'blog', name: 'Blog Post Generator', icon: <FaBlog /> },
      { id: 'email', name: 'Email Campaign Generator', icon: <FaEnvelope /> },
      { id: 'marketing', name: 'Marketing Copy Generator', icon: <FaBullhorn /> },
      { id: 'seo', name: 'SEO Content Optimizer', icon: <FaSearch /> },
      { id: 'podcastoutline', name: 'Podcast Outline Generator', icon: <FaPodcast /> },
      { id: 'videoscript', name: 'Video Script Generator', icon: <FaVideo /> },
      { id: 'repurpose', name: 'Content Repurposing Assistant', icon: <FaRecycle /> },
    ]
  },
  {
    id: 'analysis',
    name: 'Content Analysis',
    tools: [
      { id: 'headline', name: 'Headline Analyzer', icon: <FaHeading /> },
      { id: 'hashtag', name: 'Hashtag Generator', icon: <FaHashtag /> },
    ]
  },
  {
    id: 'planning',
    name: 'Content Planning',
    tools: [
      { id: 'contentcalendar', name: 'Content Calendar', icon: <FaCalendarAlt /> },
      { id: 'infographic', name: 'Infographic Planner', icon: <FaImage /> },
    ]
  },
  {
    id: 'media',
    name: 'Media Generation',
    tools: [
      { id: 'imagegenerator', name: 'Image Generator', icon: <FaImage /> },
      { id: 'description', name: 'Description Generator', icon: <FaFilm /> },
    ]
  },
];

export const allTools = toolCategories.flatMap(category => category.tools);

export const renderTool = (toolId) => {
  switch (toolId) {
    case 'social': return <SocialMediaPostGenerator />;
    case 'blog': return <BlogPostGenerator />;
    case 'email': return <EmailCampaignGenerator />;
    case 'marketing': return <MarketingCopyGenerator />;
    case 'seo': return <SEOContentOptimizer />;
    case 'podcastoutline': return <PodcastOutlineGenerator />;
    case 'infographic': return <InfographicPlanner />;
    case 'adcopy': return <AdCopyGenerator />;
    case 'translator': return <ContentTranslator />;
    case 'headline': return <HeadlineAnalyzer />;
    case 'hashtag': return <HashtagGenerator />;
    case 'contentcalendar': return <ContentCalendar />;
    case 'pressrelease': return <PressRelease />;
    case 'faqgenerator': return <FaqGenerator />;
    case 'casestudy': return <CaseStudyTemplate />;
    case 'socialpoll': return <SocialMediaPoll />;
    case 'newsletter': return <NewsletterTemplate />;
    case 'videoscript': return <VideoScriptGenerator />;
    case 'description': return <DescriptionGenerator />;
    case 'imagegenerator': return <ImageGenerator />;
    case 'repurpose': return <ContentRepurposingAssistant />;
    default: return <div>Select a tool from the sidebar</div>;
  }
};