import { useNavigate } from 'react-router-dom';
import { SessionContext } from './sessionProvider';
import supabase from './supabase/supabaseClient';
import { useState, useContext } from 'react';

function Signin() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState('');
  const { session } = useContext(SessionContext);
  const navigate = useNavigate();

  if (session) {
    navigate('/');
  }

  const handleLogin = async (event) => {
    event.preventDefault();
    setLoginError('');

    try {
      const { data, error } = await supabase.auth.signInWithPassword({ email, password });
      if (error) throw error;
      if (data) {
        console.log('User signed in successfully');
        navigate('/');
      }
    } catch (error) {
      console.error('Error signing in:', error.message);
      setLoginError(error.message);
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      const { data, error } = await supabase.auth.signInWithOAuth({ provider: 'google' });
      if (error) throw error;
    } catch (error) {
      console.error('Error signing in with Google:', error.message);
      setLoginError(error.message);
    }
  };

  return (
    <div className='flex justify-center items-center h-screen bg-gradient-to-b from-blue-50 to-indigo-100'>
      <div className="w-full max-w-md bg-white rounded-lg shadow-lg p-8">
        <h1 className="text-3xl font-bold text-center text-darkSlateGray-800 mb-6">Sign In</h1>
        <form onSubmit={handleLogin} className="space-y-6">
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-darkSlateGray-700">Email:</label>
            <input
              id="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="border border-gray-300 focus:border-blue-500 focus:ring-blue-500 rounded-md p-2 w-full mt-1"
              autoComplete='email'
            />
          </div>
          <div>
            <label htmlFor="password" className="block text-sm font-medium text-darkSlateGray-700">Password:</label>
            <input
              id="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="border border-gray-300 focus:border-blue-500 focus:ring-blue-500 rounded-md p-2 w-full mt-1"
              autoComplete='current-password'
            />
          </div>
          <button
            className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 rounded transition duration-300"
            type="submit"
          >
            Sign In
          </button>
        </form>
        <div className="mt-4">
          <button
            onClick={handleGoogleSignIn}
            className="w-full bg-white border border-gray-300 text-darkSlateGray-700 font-bold py-2 rounded flex items-center justify-center hover:bg-gray-100 transition duration-300"
          >
            <img src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg" alt="Google logo" className="w-5 h-5 mr-2" />
            Sign in with Google
          </button>
        </div>
        <p className="text-sm text-darkSlateGray-700 mt-4 text-center">Don't have an account? <button onClick={() => navigate('/signup')} className="text-blue-600 hover:text-blue-700">Sign Up</button></p>
        {loginError && <p className="text-red-500 text-center mt-2">{loginError}</p>}
      </div>
    </div>
  );

  
  
}

export default Signin;