import React, { useState } from 'react';
import axios from 'axios';

function AdCopyGenerator() {
  const [product, setProduct] = useState('');
  const [targetAudience, setTargetAudience] = useState('');
  const [platform, setPlatform] = useState('facebook');
  const [tone, setTone] = useState('professional');
  const [adCopy, setAdCopy] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const apiUrl = process.env.REACT_APP_API_URL;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);
    setAdCopy(null);
    try {
      const response = await axios.post(`${apiUrl}/generate-ad-copy`, {
        product,
        targetAudience,
        platform,
        tone
      });
      if (response.data.adCopy) {
        setAdCopy(response.data.adCopy);
      } else if (response.data.error) {
        throw new Error(response.data.error);
      } else {
        throw new Error('Invalid response format');
      }
    } catch (error) {
      console.error('Error generating ad copy:', error);
      setError(error.response?.data?.error || error.message || 'An error occurred while generating the ad copy. Please try again.');
    }
    setIsLoading(false);
  };

  return (
    <div className="max-w-4xl mx-auto">
      <h2 className="text-2xl font-bold mb-4">Ad Copy Generator</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block mb-1">Product or Service:</label>
          <input
            type="text"
            value={product}
            onChange={(e) => setProduct(e.target.value)}
            className="w-full p-2 border rounded"
            required
          />
        </div>
        <div>
          <label className="block mb-1">Target Audience:</label>
          <input
            type="text"
            value={targetAudience}
            onChange={(e) => setTargetAudience(e.target.value)}
            className="w-full p-2 border rounded"
            required
          />
        </div>
        <div>
          <label className="block mb-1">Platform:</label>
          <select
            value={platform}
            onChange={(e) => setPlatform(e.target.value)}
            className="w-full p-2 border rounded"
          >
            <option value="facebook">Facebook</option>
            <option value="instagram">Instagram</option>
            <option value="twitter">Twitter</option>
            <option value="linkedin">LinkedIn</option>
            <option value="google">Google Ads</option>
          </select>
        </div>
        <div>
          <label className="block mb-1">Tone:</label>
          <select
            value={tone}
            onChange={(e) => setTone(e.target.value)}
            className="w-full p-2 border rounded"
          >
            <option value="professional">Professional</option>
            <option value="friendly">Friendly</option>
            <option value="humorous">Humorous</option>
            <option value="urgent">Urgent</option>
            <option value="inspirational">Inspirational</option>
          </select>
        </div>
        <button
          type="submit"
          className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700"
          disabled={isLoading}
        >
          {isLoading ? 'Generating...' : 'Generate Ad Copy'}
        </button>
      </form>
      {error && (
        <div className="mt-4 text-red-600">
          {error}
        </div>
      )}
      {adCopy && (
        <div className="mt-8">
          <h3 className="text-xl font-bold mb-2">Generated Ad Copy:</h3>
          <div className="bg-gray-100 p-4 rounded whitespace-pre-wrap">
            {adCopy}
          </div>
        </div>
      )}
    </div>
  );
}

export default AdCopyGenerator;