import React, { useState } from 'react';
import axios from 'axios';

function PodcastOutlineGenerator() {
  const [topic, setTopic] = useState('');
  const [duration, setDuration] = useState('');
  const [targetAudience, setTargetAudience] = useState('');
  const [outline, setOutline] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const apiUrl = process.env.REACT_APP_API_URL;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await axios.post(`${apiUrl}/generate-podcast-outline`, {
        topic,
        duration,
        targetAudience
      });
      setOutline(response.data.outline);
    } catch (error) {
      console.error('Error generating podcast outline:', error);
    }
    setIsLoading(false);
  };

  return (
    <div className="max-w-2xl mx-auto">
      <h2 className="text-2xl font-bold mb-4">Podcast Outline Generator</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block mb-1">Topic:</label>
          <input
            type="text"
            value={topic}
            onChange={(e) => setTopic(e.target.value)}
            className="w-full p-2 border rounded"
            required
          />
        </div>
        <div>
          <label className="block mb-1">Duration (in minutes):</label>
          <input
            type="number"
            value={duration}
            onChange={(e) => setDuration(e.target.value)}
            className="w-full p-2 border rounded"
            required
          />
        </div>
        <div>
          <label className="block mb-1">Target Audience:</label>
          <input
            type="text"
            value={targetAudience}
            onChange={(e) => setTargetAudience(e.target.value)}
            className="w-full p-2 border rounded"
            required
          />
        </div>
        <button
          type="submit"
          className="bg-indigo-500 text-white px-4 py-2 rounded hover:bg-indigo-600"
          disabled={isLoading}
        >
          {isLoading ? 'Generating...' : 'Generate Outline'}
        </button>
      </form>
      {outline && (
        <div className="mt-8">
          <h3 className="text-xl font-bold mb-2">Generated Outline:</h3>
          <pre className="bg-gray-100 p-4 rounded whitespace-pre-wrap">{outline}</pre>
        </div>
      )}
    </div>
  );
}

export default PodcastOutlineGenerator;