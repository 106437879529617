import React from 'react';
import { Link } from 'react-router-dom';
const VerifyEmail = () => {
    return (
        <div className="flex items-center justify-center h-screen bg-offWhite-50">
            <div className="max-w-md mx-auto p-6 bg-white shadow-lg rounded-lg border border-lightGray-300">
                <h2 className="text-2xl font-bold text-darkSlateGray-800 mb-4">Verify Your Email</h2>
                <p className="text-darkSlateGray-800 mb-4">Please check your email and click on the verification link to complete the signup process.</p>
                <p className="text-darkSlateGray-800">Once verified, you can log in to your account.</p>
               <p> <strong>Already verified your email ?</strong>   <Link to="/signin" className="text-blue-500 hover:underline"> Sign In to you account</Link> </p> 
            </div>
        </div>
    );
};

export default VerifyEmail;
      
 