import React, { useState } from 'react';
import axios from 'axios';

function NewsletterTemplate() {
  const [topic, setTopic] = useState('');
  const [audience, setAudience] = useState('');
  const [sections, setSections] = useState('');
  const [generatedTemplate, setGeneratedTemplate] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const apiUrl = process.env.REACT_APP_API_URL;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');

    try {
      const response = await axios.post(`${apiUrl}/generate-newsletter-template`, {
        topic,
        audience,
        sections: sections.split(',').map(section => section.trim())
      });

      setGeneratedTemplate(response.data.template);
    } catch (err) {
      setError('Failed to generate template. Please try again.');
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="space-y-6">
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label htmlFor="topic" className="block text-sm font-medium text-gray-700">Newsletter Topic</label>
          <input
            type="text"
            id="topic"
            value={topic}
            onChange={(e) => setTopic(e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            required
          />
        </div>
        <div>
          <label htmlFor="audience" className="block text-sm font-medium text-gray-700">Target Audience</label>
          <input
            type="text"
            id="audience"
            value={audience}
            onChange={(e) => setAudience(e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            required
          />
        </div>
        <div>
          <label htmlFor="sections" className="block text-sm font-medium text-gray-700">Sections (comma-separated)</label>
          <input
            type="text"
            id="sections"
            value={sections}
            onChange={(e) => setSections(e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            required
          />
        </div>
        <button 
          type="submit" 
          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          disabled={isLoading}
        >
          {isLoading ? 'Generating...' : 'Generate Template'}
        </button>
      </form>
      {error && <p className="text-red-500">{error}</p>}
      {generatedTemplate && (
        <div className="mt-6">
          <h3 className="text-lg font-medium text-gray-900">Generated Template</h3>
          <pre className="mt-2 p-4 bg-gray-100 rounded-md overflow-auto">{generatedTemplate}</pre>
        </div>
      )}
    </div>
  );
}

export default NewsletterTemplate;