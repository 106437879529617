import Footer from './footer';
import ReactGA from 'react-ga';
import { Link } from 'react-router-dom';
import { SessionContext } from './sessionProvider';
import { useContext, useEffect } from 'react';
import supabase from './supabase/supabaseClient';
import logo from './logo.svg';
import { FaRocket, FaMagic, FaBrain, FaChartLine } from 'react-icons/fa';
import { motion } from 'framer-motion';
import ContactForm from './components/ContactForm';
import { colors, components, typography } from './styles/designSystem';

function Home() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const { session } = useContext(SessionContext);

  const handleLogout = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) {
      console.error('Error signing out:', error);
    }
  }

  
  return (
    <div className="flex flex-col min-h-screen bg-gradient-to-b from-sky-50 to-sky-100">
      {/* Hero Section */}
      <motion.section 
        className="relative bg-gradient-to-r from-blue-600 to-purple-600 text-white py-12 sm:py-20 px-4 sm:px-6 lg:px-8"
        initial={{ opacity: 0, y: -50 }} 
        animate={{ opacity: 1, y: 0 }} 
        transition={{ duration: 0.5 }}
      >
        <div className="absolute top-0 left-0 p-4">
          <div className='flex h-16 w-16 sm:h-20 sm:w-20'>
            <img src={logo} alt="QuikDescrip Logo" className="logo-image" />
          </div>
        </div>
        <div className="absolute top-0 right-0 p-4">
          {session ? (
            <div className="flex flex-col sm:flex-row items-center">
              <p className="text-white mb-2 sm:mb-0 sm:mr-4">Welcome, {session.user.email}!</p>
              <button 
                onClick={handleLogout}
                className="font-semibold rounded-full px-4 py-2 text-sm bg-white text-blue-600 hover:bg-blue-100 transition-all"
              >
                Logout
              </button>
            </div>
          ) : (
            <div className="flex flex-col sm:flex-row">
              <Link to="/signup" className="text-white hover:text-blue-200 mb-2 sm:mb-0 sm:mr-4">Sign Up</Link>
              <Link to="/signin" className="text-white hover:text-blue-200">Sign In</Link>
            </div>
          )}
        </div>
        <div className="max-w-4xl mx-auto text-center mt-16 sm:mt-0">
          <h1 className="font-display text-4xl sm:text-5xl lg:text-6xl font-extrabold mb-6">Revolutionize Your Content Creation</h1>
          <p className="text-lg sm:text-xl lg:text-2xl mb-10">Harness the power of AI to supercharge your marketing strategy.</p>
          {session ? (
            <Link to="/dashboard">
              <button className="font-semibold rounded-full px-8 py-4 text-lg bg-emerald-600 text-white hover:bg-emerald-700 transition-all">
                Access AI Tools
              </button>
            </Link>
          ) : (
            <Link to="/signup">
              <button className="font-semibold rounded-full px-8 py-4 text-lg bg-emerald-600 text-white hover:bg-emerald-700 transition-all">
                Get Started Now
              </button>
            </Link>
          )}
        </div>
      </motion.section>

      {/* Features Section */}
      <motion.section 
        className="py-16 sm:py-20 px-4 sm:px-6 lg:px-8 bg-sky-50"
        initial={{ opacity: 0 }} 
        animate={{ opacity: 1 }} 
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        <div className="max-w-6xl mx-auto">
          <h2 className="font-display text-3xl sm:text-4xl font-bold text-center text-gray-900 mb-12">Why Choose QuikDescrip?</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 sm:gap-10">
            <FeatureCard 
              icon={<FaRocket className="text-4xl sm:text-5xl text-blue-600" />}
              title="Lightning Fast"
              description="Generate high-quality content in seconds, not hours."
            />
            <FeatureCard 
              icon={<FaMagic className="text-4xl sm:text-5xl text-purple-600" />}
              title="AI-Powered Magic"
              description="Leverage cutting-edge AI to create compelling content."
            />
            <FeatureCard 
              icon={<FaBrain className="text-4xl sm:text-5xl text-emerald-600" />}
              title="Smart Suggestions"
              description="Get intelligent recommendations to improve your content."
            />
            <FeatureCard 
              icon={<FaChartLine className="text-4xl sm:text-5xl text-blue-500" />}
              title="Boost Engagement"
              description="Create content that resonates with your audience and drives results."
            />
          </div>
        </div>
      </motion.section>

      {/* Tools Showcase */}
      <motion.section 
        className="bg-white py-16 sm:py-20 px-4 sm:px-6 lg:px-8"
        initial={{ opacity: 0 }} 
        animate={{ opacity: 1 }} 
        transition={{ duration: 0.5, delay: 0.4 }}
      >
        <div className="max-w-6xl mx-auto">
          <h2 className="font-display text-3xl sm:text-4xl font-bold text-center text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-purple-600 mb-12">Our Powerful AI Tools</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-8">
            <ToolCard title="Social Media Post Generator" description="Create engaging posts for all your social platforms." gradient="from-blue-500 to-blue-700" />
            <ToolCard title="Blog Post Generator" description="Generate SEO-optimized blog content in minutes." gradient="from-purple-500 to-purple-700" />
            <ToolCard title="Email Campaign Generator" description="Craft compelling email campaigns that convert." gradient="from-emerald-500 to-emerald-700" />
            <ToolCard title="Product Description Generator" description="Write persuasive product descriptions that sell." gradient="from-blue-500 to-purple-500" />
            <ToolCard title="Ad Copy Generator" description="Create high-converting ad copy for your campaigns." gradient="from-purple-500 to-emerald-500" />
            <ToolCard title="Video Script Generator" description="Develop engaging scripts for your video content." gradient="from-emerald-500 to-blue-500" />
          </div>
          <div className="text-center mt-12">
            {session ? (
              <Link to="/dashboard">
                <button className="font-semibold rounded-full px-8 py-4 text-lg bg-blue-600 text-white hover:bg-blue-700 transition-all">
                  Access All Tools
                </button>
              </Link>
            ) : (
              <div>
                <Link to="/signin">
                  <button className="font-semibold rounded-full px-8 py-4 text-lg bg-blue-600 text-white hover:bg-blue-700 transition-all">
                    Sign In
                  </button>
                </Link>
                <Link to="/signup">
                  <button className="font-semibold rounded-full px-8 py-4 text-lg bg-purple-600 text-white hover:bg-purple-700 transition-all">
                    Sign Up
                  </button>
                </Link>
              </div>
            )}
          </div>
        </div>
      </motion.section>

      {/* Contact Form Section */}
      <motion.section 
        className="bg-gradient-to-r from-blue-600 to-purple-600 py-16 sm:py-20 px-4 sm:px-6 lg:px-8"
        initial={{ opacity: 0 }} 
        animate={{ opacity: 1 }} 
        transition={{ duration: 0.5, delay: 0.8 }}
      >
        <div className="max-w-3xl mx-auto">
          <h2 className="font-display text-3xl sm:text-4xl font-bold text-center text-white mb-8">Get in Touch</h2>
          <p className="text-center text-lg text-gray-100 mb-8">We'd love to hear from you! Please fill out the form below.</p>
          <ContactForm />
        </div>
      </motion.section>

      {/* Call to Action */}
      <motion.section 
        className="bg-gradient-to-r from-purple-600 to-blue-600 py-16 sm:py-20 px-4 sm:px-6 lg:px-8"
        initial={{ opacity: 0 }} 
        animate={{ opacity: 1 }} 
        transition={{ duration: 0.5, delay: 1 }}
      >
        <div className="max-w-4xl mx-auto text-center">
          <h2 className="font-display text-3xl sm:text-4xl font-bold text-white mb-6">Ready to Transform Your Content Strategy?</h2>
          <p className="text-lg sm:text-xl text-white mb-10">Join thousands of marketers who are already using QuikDescrip to create amazing content.</p>
          {session ? (
            <Link to="/dashboard">
              <button className="font-semibold rounded-full px-8 py-4 text-lg bg-emerald-600 text-white hover:bg-emerald-700 transition-all">
                Go to Dashboard
              </button>
            </Link>
          ) : (
            <Link to="/signup">
              <button className="font-semibold rounded-full px-8 py-4 text-lg bg-emerald-600 text-white hover:bg-emerald-700 transition-all">
                Get Started Now
              </button>
            </Link>
          )}
        </div>
      </motion.section>

      <Footer>
        <div className="flex justify-center space-x-4 text-sm text-gray-600">
         <Link to="/privacy-policy" className="hover:text-gray-800">Privacy Policy</Link>
         <Link to="/terms-of-service" className="hover:text-gray-800">Terms of Service</Link>
        </div>
      </Footer>
    </div>
  );
}

function FeatureCard({ icon, title, description }) {
  return (
    <div className="bg-white rounded-lg shadow-md hover:shadow-lg transition-all p-6">
      <div className="flex justify-center mb-4">{icon}</div>
      <h3 className="font-display text-xl font-semibold text-gray-900 mb-2">{title}</h3>
      <p className="text-gray-600">{description}</p>
    </div>
  );
}

function ToolCard({ title, description, gradient }) {
  const { session } = useContext(SessionContext);
  return (
    <div className={`bg-white rounded-lg shadow-md hover:shadow-lg transition-all p-6 bg-gradient-to-r ${gradient}`}>
      <h3 className="font-display text-xl font-semibold text-white mb-2">{title}</h3>
      <p className="text-white mb-4">{description}</p>
      {session ? (
        <Link to="/dashboard" className="text-white hover:text-gray-200 font-semibold">Try it now →</Link>
      ) : (
        <Link to="/signin" className="text-white hover:text-gray-200 font-semibold">Sign in to try →</Link>
      )}
    </div>
  );
}

export default Home;