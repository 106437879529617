import React, { useState } from 'react';
import axios from 'axios';

const ContentRepurposingAssistant = () => {
  const [originalContent, setOriginalContent] = useState('');
  const [targetPlatforms, setTargetPlatforms] = useState([]);
  const [repurposedContent, setRepurposedContent] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const platformOptions = ['Twitter', 'LinkedIn', 'Instagram', 'Email Newsletter', 'Podcast Script'];

  const handlePlatformChange = (platform) => {
    if (targetPlatforms.includes(platform)) {
      setTargetPlatforms(targetPlatforms.filter(p => p !== platform));
    } else {
      setTargetPlatforms([...targetPlatforms, platform]);
    }
  };

  const apiUrl = process.env.REACT_APP_API_URL;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await axios.post(`${apiUrl}/repurpose-content`, {
        originalContent,
        targetPlatforms
      });
      setRepurposedContent(response.data.repurposedContent);
    } catch (error) {
      console.error('Error repurposing content:', error);
    }
    setIsLoading(false);
  };

  return (
    <div className="max-w-4xl mx-auto p-4">
      <h2 className="text-2xl font-bold mb-4">Content Repurposing Assistant</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block mb-2">Original Content:</label>
          <textarea
            className="w-full p-2 border rounded"
            rows="6"
            value={originalContent}
            onChange={(e) => setOriginalContent(e.target.value)}
            required
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2">Target Platforms:</label>
          <div className="flex flex-wrap">
            {platformOptions.map(platform => (
              <label key={platform} className="mr-4 mb-2">
                <input
                  type="checkbox"
                  checked={targetPlatforms.includes(platform)}
                  onChange={() => handlePlatformChange(platform)}
                  className="mr-2"
                />
                {platform}
              </label>
            ))}
          </div>
        </div>
        <button
          type="submit"
          className="bg-blue-500 text-white px-4 py-2 rounded"
          disabled={isLoading}
        >
          {isLoading ? 'Repurposing...' : 'Repurpose Content'}
        </button>
      </form>
      {repurposedContent && (
        <div className="mt-8">
          <h3 className="text-xl font-bold mb-4">Repurposed Content:</h3>
          {Object.entries(repurposedContent).map(([platform, content]) => (
            <div key={platform} className="mb-6">
              <h4 className="text-lg font-semibold mb-2">{platform}</h4>
              <div className="bg-gray-100 p-4 rounded">
                <p className="font-bold">{content.title}</p>
                <p className="mt-2">{content.content}</p>
                {content.tips && (
                  <p className="mt-2 text-sm text-gray-600">Tips: {content.tips}</p>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ContentRepurposingAssistant;