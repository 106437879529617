import React from 'react';
import { Link } from 'react-router-dom';

function PrivacyPolicy() {
  return (
    <div className="min-h-screen bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto">
        <Link to="/" className="text-blue-600 hover:text-blue-800 mb-8 inline-block">&larr; Back to Home</Link>
        <h1 className="text-3xl font-bold mb-6">Privacy Policy</h1>
        
        <p className="mb-4">At QuikDescrip, we are committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our website and services.</p>
        
        <h2 className="text-2xl font-semibold mt-6 mb-3">1. Information We Collect</h2>
        <p className="mb-4">We collect information that you provide directly to us, such as when you create an account, use our services, or communicate with us. This may include your name, email address, and any other information you choose to provide.</p>
        
        <h2 className="text-2xl font-semibold mt-6 mb-3">2. How We Use Your Information</h2>
        <p className="mb-4">We use the information we collect to provide, maintain, and improve our services, to communicate with you, and to comply with legal obligations.</p>
        
        <h2 className="text-2xl font-semibold mt-6 mb-3">3. Sharing of Your Information</h2>
        <p className="mb-4">We do not sell, trade, or rent your personal information to third parties. We may share your information with service providers who assist us in operating our website and conducting our business.</p>
        
        <h2 className="text-2xl font-semibold mt-6 mb-3">4. Data Security</h2>
        <p className="mb-4">We implement reasonable security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction.</p>
        
        <h2 className="text-2xl font-semibold mt-6 mb-3">5. Your Choices</h2>
        <p className="mb-4">You may access, update, or delete your account information at any time by logging into your account or contacting us directly.</p>
        
        <h2 className="text-2xl font-semibold mt-6 mb-3">6. Children's Privacy</h2>
        <p className="mb-4">Our services are not intended for children under the age of 13. We do not knowingly collect personal information from children under 13.</p>
        
        <h2 className="text-2xl font-semibold mt-6 mb-3">7. Changes to This Privacy Policy</h2>
        <p className="mb-4">We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
        
        <p className="mt-8">Last updated: {new Date().toLocaleDateString()}</p>
      </div>
    </div>
  );
}

export default PrivacyPolicy;