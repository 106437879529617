import React, { useState } from 'react';

const TemplateManager = ({ templates, onSave, onLoad, onDelete }) => {
  const [templateName, setTemplateName] = useState('');

  return (
    <div className="mb-4">
      <h3 className="text-lg font-semibold mb-2">Template Management</h3>
      <div className="flex items-center mb-2">
        <input
          type="text"
          value={templateName}
          onChange={(e) => setTemplateName(e.target.value)}
          placeholder="Enter template name"
          className="w-full p-2 mr-2 border rounded"
        />
        <button
          onClick={() => {
            onSave(templateName);
            setTemplateName('');
          }}
          className="py-2 px-4 bg-green-500 text-white rounded hover:bg-green-600"
        >
          Save Template
        </button>
      </div>
      <div className="mt-4">
        <h4 className="font-semibold mb-2">Saved Templates:</h4>
        <ul className="space-y-2">
          {templates.map((template) => (
            <li key={template.id} className="flex items-center justify-between bg-gray-100 p-2 rounded">
              <span>{template.name}</span>
              <div>
                <button
                  onClick={() => onLoad(template)}
                  className="py-1 px-2 bg-blue-500 text-white rounded mr-2 hover:bg-blue-600"
                >
                  Load
                </button>
                <button
                  onClick={() => onDelete(template.id)}
                  className="py-1 px-2 bg-red-500 text-white rounded hover:bg-red-600"
                >
                  Delete
                </button>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default TemplateManager;
