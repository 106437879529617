import React, { useState } from 'react';
import axios from 'axios';

function PressRelease() {
  const [companyName, setCompanyName] = useState('');
  const [announcement, setAnnouncement] = useState('');
  const [industry, setIndustry] = useState('');
  const [pressRelease, setPressRelease] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const apiUrl = process.env.REACT_APP_API_URL;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);
    setPressRelease('');
    try {
      const response = await axios.post(`${apiUrl}/generate-press-release`, {
        companyName,
        announcement,
        industry
      });
      if (response.data.pressRelease) {
        setPressRelease(response.data.pressRelease);
      } else if (response.data.error) {
        throw new Error(response.data.error);
      } else {
        throw new Error('Invalid response format');
      }
    } catch (error) {
      console.error('Error generating press release:', error);
      setError(error.response?.data?.error || error.message || 'An error occurred while generating the press release. Please try again.');
    }
    setIsLoading(false);
  };

  return (
    <div className="max-w-4xl mx-auto">
      <h2 className="text-2xl font-bold mb-4">Press Release Generator</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block mb-1">Company Name:</label>
          <input
            type="text"
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
            className="w-full p-2 border rounded"
            required
          />
        </div>
        <div>
          <label className="block mb-1">Announcement:</label>
          <textarea
            value={announcement}
            onChange={(e) => setAnnouncement(e.target.value)}
            className="w-full p-2 border rounded"
            rows="4"
            required
          ></textarea>
        </div>
        <div>
          <label className="block mb-1">Industry:</label>
          <input
            type="text"
            value={industry}
            onChange={(e) => setIndustry(e.target.value)}
            className="w-full p-2 border rounded"
            required
          />
        </div>
        <button
          type="submit"
          className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700"
          disabled={isLoading}
        >
          {isLoading ? 'Generating...' : 'Generate Press Release'}
        </button>
      </form>
      {error && (
        <div className="mt-4 text-red-600">
          {error}
        </div>
      )}
      {pressRelease && (
        <div className="mt-8">
          <h3 className="text-xl font-bold mb-2">Generated Press Release:</h3>
          <div className="bg-gray-100 p-4 rounded whitespace-pre-wrap">
            {pressRelease}
          </div>
        </div>
      )}
    </div>
  );
}

export default PressRelease;