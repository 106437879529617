import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import DescriptionGenerator from './description';
import Home from './home';
import Experiment from './experiment';
import Signin from './signin';
import Signup from './signup';
import VerifyEmail from './user/verify';
import Success from './components/success';
import Failure from './components/failure';
import ToolsDashboard from './dashboard/ToolsDashboard';
import ReactGA from 'react-ga';
import Blog from './components/blog';
import BlogPost from './components/Blogpost';
import ImageGenerator from './components/generateimage';
import UserDashboard from './dashboard/Dashboard';
import TermsOfService from './components/TermsOfService';
import PrivacyPolicy from './components/PrivacyPolicy';
import ContentRepurposingAssistant from './components/ContentRepurposingAssistant';

const trackingId = "G-80RNZKKT2Y"; // Replace with your Google Analytics tracking ID

ReactGA.initialize(trackingId);

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/product" element={<DescriptionGenerator />} />
        <Route path="/experiment" element={<Experiment />} />
        <Route path="/signin" element={<Signin />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/verify" element={<VerifyEmail />} />
        <Route path="/success" element={<Success />} />
        <Route path="/failure" element={<Failure />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/:postId" element={<BlogPost />} />
        <Route path="/generateimage" element={<ImageGenerator />} />
        <Route path="/dashboard/*" element={<ToolsDashboard />} />
        <Route path="/userdashboard" element={<UserDashboard />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/content-repurposing-assistant" element={<ContentRepurposingAssistant />} />
        <Route path="*" element={<h1>Not Found</h1>} />
      </Routes>
    </Router>
  );
}

export default App;