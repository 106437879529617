import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaHome, FaArrowUp, FaBars, FaTimes, FaChevronDown, FaChevronUp, FaRecycle } from 'react-icons/fa';
import { toolCategories } from '../dashboard/toolsData';

function Sidebar({ setActiveTool, activeTool, isOpen, toggleSidebar }) {
  const location = useLocation();
  const [expandedCategories, setExpandedCategories] = useState({});

  const handleToolClick = (toolId) => {
    setActiveTool(toolId);
    if (window.innerWidth < 768) {
      toggleSidebar();
    }
  };

  const toggleCategory = (categoryId) => {
    setExpandedCategories(prev => ({
      ...prev,
      [categoryId]: !prev[categoryId]
    }));
  };

  return (
    <>
      <button
        className="md:hidden fixed top-4 left-4 z-20 font-semibold rounded-full px-4 py-2 text-sm bg-blue-600 text-white hover:bg-blue-700 transition-all"
        onClick={toggleSidebar}
      >
        {isOpen ? <FaTimes /> : <FaBars />}
      </button>
      <div className={`${isOpen ? 'translate-x-0' : '-translate-x-full'} md:translate-x-0 fixed md:static inset-y-0 left-0 z-10 w-64 bg-gradient-to-b from-blue-600 to-blue-800 text-white h-screen flex flex-col overflow-auto transition-transform duration-300 ease-in-out`}>
        <div className="p-6">
          <Link
            to="/"
            className="flex items-center text-white hover:text-blue-200 transition-colors duration-200 mb-6"
          >
            <FaHome className="mr-2 text-2xl" />
            <span className="text-2xl font-bold">Home</span>
          </Link>
          <h2 className="text-xl font-bold mb-4">AI Tools</h2>
          <nav className="space-y-2">
            {toolCategories.map((category) => (
              <div key={category.id}>
                <button
                  onClick={() => toggleCategory(category.id)}
                  className="flex items-center justify-between w-full text-left px-4 py-2 rounded-lg hover:bg-blue-700 transition-colors duration-200"
                >
                  <span>{category.name}</span>
                  {expandedCategories[category.id] ? <FaChevronUp /> : <FaChevronDown />}
                </button>
                {expandedCategories[category.id] && (
                  <div className="ml-4 mt-2 space-y-2">
                    {category.tools.map((tool) => (
                      <Link
                        key={tool.id}
                        to={`/dashboard/${tool.id}`}
                        onClick={() => handleToolClick(tool.id)}
                        className={`flex items-center w-full px-4 py-2 rounded-lg transition-all duration-200 ${
                          location.pathname.includes(tool.id)
                            ? 'bg-white text-blue-600 shadow-lg'
                            : 'hover:bg-blue-700'
                        }`}
                      >
                        <span className="mr-3">{tool.icon}</span>
                        {tool.name}
                      </Link>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </nav>
        </div>
        <div className="mt-auto p-6">
          <button
            onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
            className="flex items-center text-white hover:text-blue-200 transition-colors duration-200"
          >
            <FaArrowUp className="mr-2" />
            Back to Top
          </button>
        </div>
      </div>
    </>
  );
  
}

export default Sidebar;