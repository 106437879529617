import React from 'react';

function GeneratedOutput({ output, isLoading }) {
  return (
    <div className="mt-8 bg-gray-100 p-4 rounded-lg">
      <h2 className="text-xl font-semibold mb-2">Generated Social Media Post:</h2>
      <div className="whitespace-pre-wrap text-gray-800 font-sans text-sm">
        {output}
        {isLoading && <span className="animate-pulse">|</span>}
      </div>
    </div>
  );
}

export default GeneratedOutput;