import React, { useState, createContext, useEffect } from 'react';
import supabase from './supabase/supabaseClient';

// Create the context
export const SessionContext = createContext();

export function SessionProvider({ children }) {

const [session, setSession] = useState(null);

const [totalWordsGenerated, setTotalWordsGenerated] = useState(0);
const [generatedDescriptionCount, setGeneratedDescriptionCount] = useState(0);
const [subscription, setSubscription] = useState(null);
const [freeTrialEnded, setFreeTrialEnded] = useState(false); // Add a new state for free trial ended
const [planLimit, setPlanLimit] = useState(0); // Add a new state for the plan limit

const [userPlan, setUserPlan] = useState(null);


const fetchUserPlan = async () => {
  const { data, error } = await supabase
      .from('subscriptions')
      .select('subscription_status')
      .eq('user_id', session.user.id)
      .single();

  if (error) {
      console.error('Error fetching user plan:', error);
      return null;
  }

  return data;
};


const upgradePlan = async () => {
  const { data, error } = await supabase
      .from('subscriptions')
      .update({ subscription_status: 'Pro' })
      .eq('user_id', session.user.id);

  if (error) {
      console.error('Error upgrading plan:', error);
      return;
  }

  setUserPlan(data);
};

const cancelSubscription = async () => {
  const { data, error } = await supabase
      .from('subscriptions')
      .update({ subscription_status: 'Canceled' })
      .eq('user_id', session.user.id);

  if (error) {
      console.error('Error canceling subscription:', error);
      return;
  }

  setUserPlan(data);
}



useEffect(() => {
  if (session) {
      fetchUserPlan().then((data) => {
          setUserPlan(data);
      });
  }
}
, [session]);


// Function to get the plan limit based on the subscription status
const getPlanLimit = (subscriptionStatus) => {
  switch (subscriptionStatus) {
      case 'trail':
          return 100; // Replace with your actual limit for the 'trail' plan
      case 'basic':
          return 500; // Replace with your actual limit for the 'basic' plan
      case 'pro':
          return 2000; // Replace with your actual limit for the 'pro' plan
      default:
          return 0;
  }
};

// Update the planLimit state when the userPlan state changes
useEffect(() => {
  if (userPlan) {
      setPlanLimit(getPlanLimit(userPlan.subscription_status));
  }
}, [userPlan]);

// ... rest of your code







 

 

// Update the freeTrialEnded state when the subscription state changes




const fetchGeneratedDescriptionCount = async (user_id) => {
  const { count, error } = await supabase
    .from('product_descriptions')
    .select('generated_description', { count: 'exact' })
    .eq('user', user_id);

  if (error) {
    console.error('Error fetching generated descriptions:', error);
    return;
  }

  setGeneratedDescriptionCount(count);
};

const fetchTotalWordsGenerated = async (user_id) => {
  const { data, error } = await supabase
    .from('product_descriptions')
    .select('generated_description')
    .eq('user', user_id);

  if (error) {
    console.error('Error fetching generated descriptions:', error);
    return;
  }

  const totalWords = data.reduce((sum, description) => {
    const wordCount = description.generated_description.split(' ').length;
    return sum + wordCount;
  }, 0);

  setTotalWordsGenerated(totalWords);
};




    useEffect(() => {
        supabase.auth.getSession().then(({ data: { session } }) => {
          setSession(session);
        }
        );
    
        const {
          data: { subscription },
        } = supabase.auth.onAuthStateChange((_event, session,) => {
          setSession(session);
        }
        );
    
        return () => subscription.unsubscribe();
      }
      , []);

      useEffect(() => {
        if (session) {
          fetchGeneratedDescriptionCount(session.user.id);
        }
      }, [session]);



       // Fetch totalWordsGenerated when the session changes
  useEffect(() => {
    if (session) {
      fetchTotalWordsGenerated(session.user.id);
    }
  }, [session]);


  // Add a function to fetch or insert subscription data
const fetchOrInsertSubscriptionData = async () => {
    const { data, error } = await supabase
        .from('subscriptions')
        .select('*')
        .eq('user_id', session.user.id); // Use session.user.id to fetch the subscription data

    if (error) {
        console.error('Error fetching subscription data:', error);
        return;
    }

    if (data.length === 0) {
        // No subscription data found for the user, insert a new subscription
        const { data: insertData, error: insertError } = await supabase
            .from('subscriptions')
            .insert({
                user_id: session.user.id,
                created_at: new Date(),
                subscription_status: 'trail' // Replace with your actual subscription status
            });

        if (insertError) {
            console.error('Error inserting subscription data:', insertError);
            return;
        }

        setSubscription(insertData[0]);
    } else {
        setSubscription(data[0]); // Assume that there's only one subscription per user
    }
};

// Call fetchOrInsertSubscriptionData when the session changes
useEffect(() => {
    if (session) {
        fetchOrInsertSubscriptionData(session.user.id);
    }
}, [session]);


// Add a function to update the subscription status
const updateSubscriptionStatus = async (status) => {
    const { data, error } = await supabase
        .from('subscriptions')
        .update({ subscription_status: status })
        .eq('id', subscription.id);

    if (error) {
        console.error('Error updating subscription status:', error);
        return;
    }

    setSubscription(data[0]);
}




   
    return (
      <SessionContext.Provider value={{ session, setSession, totalWordsGenerated, generatedDescriptionCount, userPlan, setUserPlan, subscription, setSubscription, freeTrialEnded, setFreeTrialEnded, planLimit, setPlanLimit, upgradePlan, cancelSubscription, fetchOrInsertSubscriptionData, updateSubscriptionStatus }}>
      {children}
  </SessionContext.Provider>
    );
}

