import React, { useState } from 'react';
import axios from 'axios';



const Feedback = () => {
  const [feedback, setFeedback] = useState('');

  const [loading, setLoading] = useState(false);
  const [feedbackSent, setFeedbackSent] = useState(false);


  

  const handleFeedbackChange = (event) => {
    setFeedback(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post('https://gentle-fortress-20969-76f4ba49f7d9.herokuapp.com/send_feedback', {
        feedback: feedback
      });
  
      if (response.status === 200) {
        setLoading(false);

        setFeedback('');
        setFeedbackSent(true);
      }
    } catch (error) {
      console.error('Error sending feedback:', error);
    }
  };
  return (
    <div className="max-w-md w-full bg-white shadow-lg rounded-lg border border-lightGray-300 p-6">
        <h2 className="text-xl font-bold text-darkSlateGray-800 mb-4">Feedback / Feature request</h2>
        <p className="text-darkSlateGray-800 mb-4">Your feedback helps us improve. Let us know your thoughts!</p>
        <form onSubmit={handleSubmit}>
            <textarea
                className="w-full bg-white border border-lightGray-300 rounded p-2 mb-4"
                value={feedback}
                onChange={handleFeedbackChange}
                placeholder="Enter your feedback"
            ></textarea>
            <button className="w-full bg-vibrantCoral-500 text-blue  py-2 px-4 rounded bg-teal-400  hover:bg-teal-500" type="submit">Send Feedback</button>
            {loading && (
                <p className="text-darkSlateGray-800 mt-4">Sending feedback...</p>
            )
            
            }

            {feedbackSent && (
                <p className="text-darkSlateGray-800 mt-4">Feedback sent! Thank you </p>
            )
            
            }
        </form>
    </div>
  );
};

export default Feedback;


    


