import React, { useState } from 'react';
import axios from 'axios';

function FAQGenerator() {
  const [topic, setTopic] = useState('');
  const [numQuestions, setNumQuestions] = useState(5);
  const [faqs, setFaqs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const apiUrl = process.env.REACT_APP_API_URL;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);
    setFaqs([]);
    try {
      const response = await axios.post(`${apiUrl}/generate-faqs`, {
        topic,
        numQuestions
      });
      if (response.data.faqs && Array.isArray(response.data.faqs)) {
        setFaqs(response.data.faqs);
      } else if (response.data.error) {
        throw new Error(response.data.error);
      } else {
        throw new Error('Invalid response format');
      }
    } catch (error) {
      console.error('Error generating FAQs:', error);
      setError(error.response?.data?.error || error.message || 'An error occurred while generating FAQs. Please try again.');
    }
    setIsLoading(false);
  };

  return (
    <div className="max-w-4xl mx-auto">
      <h2 className="text-2xl font-bold mb-4">FAQ Generator</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block mb-1">Topic:</label>
          <input
            type="text"
            value={topic}
            onChange={(e) => setTopic(e.target.value)}
            className="w-full p-2 border rounded"
            required
          />
        </div>
        <div>
          <label className="block mb-1">Number of Questions:</label>
          <input
            type="number"
            value={numQuestions}
            onChange={(e) => setNumQuestions(parseInt(e.target.value))}
            min="1"
            max="20"
            className="w-full p-2 border rounded"
            required
          />
        </div>
        <button
          type="submit"
          className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700"
          disabled={isLoading}
        >
          {isLoading ? 'Generating...' : 'Generate FAQs'}
        </button>
      </form>
      {error && (
        <div className="mt-4 text-red-600">
          {error}
        </div>
      )}
      {faqs.length > 0 && (
        <div className="mt-8">
          <h3 className="text-xl font-bold mb-2">Generated FAQs:</h3>
          <div className="bg-gray-100 p-4 rounded">
            {faqs.map((faq, index) => (
              <div key={index} className="mb-4">
                <p className="font-bold">Q: {faq.question}</p>
                <p>A: {faq.answer}</p>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default FAQGenerator;