import React, { useState, useEffect, useContext } from 'react';
import { Route, Routes, useLocation, Navigate, useNavigate } from 'react-router-dom';
import { SessionContext } from '../sessionProvider';
import { Helmet } from 'react-helmet';
import { FaBars } from 'react-icons/fa';
import { allTools, renderTool } from './toolsData';
import Sidebar from '../components/sidebar';

function Dashboard() {
  const { session } = useContext(SessionContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [activeToolId, setActiveToolId] = useState('');
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  useEffect(() => {
    const currentPath = location.pathname.split('/').pop();
    const currentTool = allTools.find(tool => tool.id === currentPath);
    if (currentTool) {
      setActiveToolId(currentTool.id);
    } else {
      // Set default tool if no match found
      const defaultToolId = allTools[0].id;
      setActiveToolId(defaultToolId);
      navigate(`/dashboard/${defaultToolId}`, { replace: true });
    }
  }, [location, navigate]);

  useEffect(() => {
    const handleResize = () => {
      setIsSidebarOpen(window.innerWidth >= 768);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (!session) {
    return <Navigate to="/signin" state={{ from: location }} replace />;
  }

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const getMetaData = () => {
    const activeTool = allTools.find(tool => tool.id === activeToolId);
    const toolName = activeTool?.name || 'AI Tools Dashboard';
    const description = `Use our AI-powered ${toolName} to enhance your content creation process.`;
    const canonicalUrl = `https://quikdescrip.com${location.pathname}`;

    return { toolName, description, canonicalUrl };
  };

  const { toolName, description, canonicalUrl } = getMetaData();

  const renderActiveTool = () => {
    return renderTool(activeToolId);
  };

  return (
    <div className="flex flex-col md:flex-row h-screen bg-sky-50">
      <button
        className="md:hidden fixed top-4 left-4 z-20 font-semibold rounded-full px-4 py-2 text-sm bg-blue-600 text-white hover:bg-blue-700 transition-all"
        onClick={toggleSidebar}
      >
        <FaBars />
      </button>
      <Sidebar 
        setActiveTool={setActiveToolId} 
        activeTool={activeToolId} 
        isOpen={isSidebarOpen}
        toggleSidebar={toggleSidebar}
      />
      <div className="flex-1 flex flex-col overflow-hidden">
        <div className="border-b p-4 bg-sky-100">
          <h1 className="font-sans text-2xl font-semibold text-center md:text-left text-gray-800">{toolName}</h1>
        </div>
        <div className="flex-1 overflow-auto p-6">
          <div className="max-w-4xl mx-auto">
            <Helmet>
              <title>{toolName} | QuikDescrip</title>
              <meta name="description" content={description} />
              <link rel="canonical" href={canonicalUrl} />
            </Helmet>
            {renderActiveTool()}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;