import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { SessionContext } from './sessionProvider';
import Signin from './signin';
import supabase from './supabase/supabaseClient';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Loading from './components/loading';
import TemplateManager from './components/TemplateManager';
import DescriptionOutput from './components/DescriptionOutput';
import DescriptionList from './components/DescriptionList';

const apiUrl = process.env.REACT_APP_API_URL;

/**
 * DescriptionGenerator Component
 *
 * The `DescriptionGenerator` component is a React component that provides an interface for generating product descriptions based on user inputs.
 *
 * Props:
 * This component does not accept any props.
 *
 * State:
 * This component maintains the following state:
 * - `productName`: The name of the product for which to generate a description.
 * - `userPrompt`: Additional details or specific instructions for the description.
 * - `description`: The generated product description.
 * - `error`: Any error messages that occur during the description generation process.
 * - `numWords`: The number of words for the generated description.
 * - `style`: The style of the description.
 * - `platform`: The e-commerce platform for which the description is being generated.
 * - `generatedDescriptions`: An array of all generated descriptions.
 * - `searchTerm`: The current search term for filtering generated descriptions.
 * - `session`: The current user session.
 * - `descriptions`: The descriptions fetched from the Supabase database.
 * - `templates`: An array of saved templates.
 * - `templateName`: The name of the current template being created.
 * - `selectedTemplate`: The currently selected template.
 *
 * Methods:
 * This component defines the following methods:
 * - `fetchDescriptions`: Fetches descriptions from the Supabase database.
 * - `handleSearchChange`: Updates the `searchTerm` state based on user input.
 * - `goBackToHome`: Navigates back to the home page.
 * - `handleSubmit`: Handles the form submission, generates a new description, and stores it in the Supabase database.
 * - `copyToClipboard`: Copies a given text to the clipboard.
 * - `saveTemplate`: Saves the current settings as a template.
 * - `loadTemplate`: Loads a saved template into the form.
 * - `deleteTemplate`: Deletes a saved template.
 *
 * Rendered JSX Elements:
 * This component renders a form for the user to input product details and a list of generated descriptions. If the user is not signed in, it renders the `Signin` component instead.
 *
 * External Dependencies:
 * This component uses the following external libraries:
 * - `react`: For creating the component and managing state and effects.
 * - `axios`: For making HTTP requests to the description generation API.
 * - `supabase`: For interacting with the Supabase database.
 * - `react-router-dom`: For navigation and routing.
 */



function DescriptionGenerator() {
    const [productName, setProductName] = useState('');
    const [userPrompt, setUserPrompt] = useState('');
    const [description, setDescription] = useState('');
    const [error, setError] = useState('');
    const [numWords, setNumWords] = useState('');
    const [style, setStyle] = useState('');
    const [platform, setPlatform] = useState('');
    const [generatedDescriptions, setGeneratedDescriptions] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const { session, setSession, planLimit, generatedDescriptionCount } = useContext(SessionContext);
    const [descriptions, setDescriptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [descriptionA, setDescriptionA] = useState('');
    const [descriptionB, setDescriptionB] = useState('');
    const [isABTesting, setIsABTesting] = useState(false);
    const [templates, setTemplates] = useState([]);
    const [templateName, setTemplateName] = useState('');
    const [selectedTemplate, setSelectedTemplate] = useState(null);



    const navigate = useNavigate();


    
    if (session) {
      localStorage.setItem('user_email', session.user.email);
      localStorage.setItem('user_id', session.user.id);
  }


    useEffect(() => {
      const fetchDescriptions = async () => {
        const { data, error } = await supabase
          .from('product_descriptions')
          .select('generated_description')
          .eq('user', localStorage.getItem('user_id'))
          
          ;
        if (error) {
          console.log(error);
        } else {
          setDescriptions(data);
        }
      }
      fetchDescriptions();
    }
    , []);

    useEffect(() => {
        const fetchTemplates = async () => {
            try {
                const userId = localStorage.getItem('user_id');
                console.log('Fetching templates for user:', userId);
                const { data, error } = await supabase
                    .from('templates')
                    .select('*')
                    .eq('user', userId);
                if (error) {
                    console.error('Error fetching templates:', error);
                    throw error;
                }
                console.log('Templates fetched successfully:', data);
                setTemplates(data);
            } catch (err) {
                console.error('Failed to fetch templates:', err);
                setError('Failed to fetch templates');
            }
        };
        fetchTemplates();
    }, []);

    

    const handleSearchChange = (event) => {
      setSearchTerm(event.target.value.toLowerCase());
    };

    /**
     * Filtered descriptions based on the search term.
     * @type {Array<string>}
     */
    const filteredDescriptions = generatedDescriptions.filter(description =>
      description.toLowerCase().includes(searchTerm)
    );


   const goBackToHome = () => {
navigate('/');
  
};

   


    if (!session) {
      return <Signin />;

    }



    const email = localStorage.getItem('user_email');

    const handleSubmit = async () => {
        try {
            setLoading(true);
            // ... existing validation code ...

            const jwtToken = localStorage.getItem('jwt_token'); // Retrieve the JWT token from localStorage

            if (!jwtToken) {
                throw new Error('No JWT token found. Please log in again.');
            }

            const templateData = selectedTemplate || {
                product_name: productName,
                num_words: numWords,
                style: style,
                prompt: userPrompt,
                platform: platform,
            };

            const response = await axios.post(`${apiUrl}/generate-description`, {
                ...templateData,
                is_ab_testing: isABTesting
            }, {
                headers: {
                    'Authorization': `Bearer ${jwtToken}`
                }
            });

            if (isABTesting) {
                setDescriptionA(response.data.descriptions[0]);
                setDescriptionB(response.data.descriptions[1]);
                setDescription(''); // Clear the main description
            } else {
                setDescription(response.data.descriptions[0]);
                setDescriptionA('');
                setDescriptionB('');
            }

            setError('');
            setLoading(false);
        } catch (err) {
            setError(err.message || 'Failed to generate description.');
            console.error(err);
            setLoading(false);
        }
    };

    const copyToClipboard = async (text) => {
        try {
            await navigator.clipboard.writeText(text);
            console.log('Copied to clipboard');
        } catch (err) {
            console.error('Failed to copy text: ', err);
        }
    };

    const saveTemplate = async () => {
        if (!templateName) {
            setError('Please enter a template name');
            return;
        }
        const newTemplate = {
            name: templateName,
            productName,
            numWords,
            style,
            platform,
            userPrompt,
            user: localStorage.getItem('user_id')
        };
        try {
            console.log('Saving template:', newTemplate);
            const { data, error } = await supabase
                .from('templates')
                .insert([newTemplate])
                .select();
            if (error) {
                console.error('Supabase error:', error);
                throw error;
            }
            console.log('Template saved successfully:', data);
            setTemplates([...templates, data[0]]);
            setTemplateName('');
            setError('');
        } catch (err) {
            console.error('Error saving template:', err);
            setError(`Failed to save template: ${err.message || 'Unknown error'}`);
        }
    };

    const loadTemplate = (template) => {
        setProductName(template.productName);
        setNumWords(template.numWords);
        setStyle(template.style);
        setPlatform(template.platform);
        setUserPrompt(template.userPrompt);
        setSelectedTemplate(template);
    };

    const deleteTemplate = async (id) => {
        try {
            const { error } = await supabase
                .from('templates')
                .delete()
                .eq('id', id);
            if (error) throw error;
            setTemplates(templates.filter(t => t.id !== id));
            if (selectedTemplate && selectedTemplate.id === id) {
                setSelectedTemplate(null);
            }
        } catch (err) {
            setError('Failed to delete template');
            console.error(err);
        }
    };

    return (
      <div className=" flex flex-col md:flex-row h-screen bg-offWhite-50 ">
      <div className="w-full md:w-1/2  flex flex-col justify-center items-center bg-slateBlue-50 p-0">

      <div className="flex items-end">
  
  
</div>

<div>
  <button
    onClick={goBackToHome}
    className="py-2 px-4 ml-4 text-offWhite-50 bg-softSkyBlue-500 rounded font-bold transition duration-300 hover:bg-vibrantCoral-500"
  >
    Back to Home
  </button>

  <Link to="/userdashboard">
    <button
      className="py-2 px-4 ml-4 text-offWhite-50 bg-softSkyBlue-500 rounded font-bold transition duration-300 hover:bg-vibrantCoral-500"
    >
      Go to Dashboard
    </button>
  </Link>

 
</div>

    
  <h1 className="text-2xl font-bold mb-6">
    <span className="bg-gradient-to-r from-teal-400 to-blue-500 text-transparent bg-clip-text">
      AI Product Description Generator
    </span>
  </h1>  
  <div className="max-w-lg mx-auto p-6 bg-offWhite-50 shadow-lg rounded-lg border border-lightGray-300">
    <input 
      type="text" 
      value={productName} 
      onChange={(e) => setProductName(e.target.value)} 
      placeholder="Enter product name"
      className="w-full p-3 mb-4 text-darkSlateGray-800 bg-lightGray-50 border border-lightGray-300 rounded focus:ring-softSkyBlue-500 focus:border-softSkyBlue-500 transition duration-300"
    />
    <input 
      type="number" 
      value={numWords} 
      onChange={(e) => setNumWords(e.target.value)} 
      placeholder="Choose number of words"
      className="w-full p-3 mb-4 text-darkSlateGray-800 bg-lightGray-50 border border-lightGray-300 rounded focus:ring-softSkyBlue-500 focus:border-softSkyBlue-500 transition duration-300"
    />
    <select
      value={platform}
      onChange={(e) => setPlatform(e.target.value)}
      className="w-full p-3 mb-4 text-darkSlateGray-800 bg-lightGray-50 border border-lightGray-300 rounded focus:ring-softSkyBlue-500 focus:border-softSkyBlue-500 transition duration-300"
    >
      <option value="">Select a platform</option>
            <option value="amazon">Amazon</option>
            <option value="shopify">Shopify</option>
            <option value="ebay">Ebay</option>
            <option value="etsy">Etsy</option>
            <option value="walmart">Walmart</option>
            <option value="target">Target</option>
            <option value="flipkart">Flipkart</option>
            <option value="snapdeal">Snapdeal</option>
            <option value="bestbuy">Best Buy</option>
            <option value="costco">Costco</option>
            <option value="homedepot">Home Depot</option>
            <option value="lowes">Lowe's</option>
            <option value="sears">Sears</option>
            <option value="wayfair">Wayfair</option>
            <option value="canadiantire">Canadian Tire</option>
            <option value="thebay">The Bay</option>
            <option value="lululemon">Lululemon</option>
            <option value="indigo">Indigo</option>
            <option value="staples">Staples</option>
            <option value="hudsonsbay">Hudson's Bay</option>

      {/* Options */}

    </select>
    <select
      value={style}
      onChange={(e) => setStyle(e.target.value)}
      className="w-full p-3 mb-4 text-darkSlateGray-800 bg-lightGray-50 border border-lightGray-300 rounded focus:ring-softSkyBlue-500 focus:border-softSkyBlue-500 transition duration-300"
    >
      {/* Options */}
      <option value="">Select a style</option>
            <option value="formal">Formal</option>
            <option value="casual">Casual</option>
            <option value="professional">Professional</option>
            <option value="informal">Informal</option>
            <option value="technical">Technical</option>
            <option value="academic">Academic</option>
            <option value="sales">Sales</option>
            <option value="marketing">Marketing</option>
            <option value="humorous">Humorous</option>
            <option value="funny">Funny</option>
            <option value="elegant">Elegant</option>
            <option value="trendy">Trendy</option>
            <option value="luxurious">Luxurious</option>
            <option value="minimalistic">Minimalistic</option>
            <option value="vintage">Vintage</option>
            <option value="bohemian">Bohemian</option>
            <option value="sporty">Sporty</option>
            <option value="sophisticated">Sophisticated</option>
            <option value="modern">Modern</option>
            <option value="chic">Chic</option>

    </select>
    <textarea 
      value={userPrompt} 
      onChange={(e) => setUserPrompt(e.target.value)}
      placeholder="Enter additional details or specific instructions"
      className="w-full p-3 mb-4 text-darkSlateGray-800 bg-lightGray-50 border border-lightGray-300 rounded h-12 focus:ring-softSkyBlue-500 focus:border-softSkyBlue-500 transition duration-300"
      rows="4"
    />
    <div className="mb-4">
        <label className="flex items-center">
            <input
                type="checkbox"
                checked={isABTesting}
                onChange={(e) => setIsABTesting(e.target.checked)}
                className="mr-2"
            />
            Enable A/B Testing
        </label>
    </div>
    <TemplateManager 
      templates={templates}
      onSave={saveTemplate}
      onLoad={loadTemplate}
      onDelete={deleteTemplate}
    />
    <button 
      onClick={handleSubmit}
      className="w-full py-3 px-6 mb-4 text-2xl text-offWhite-50 bg-gradient-to-r from-teal-400 to-blue-500 text-transparent bg-clip-text rounded-md"
    >
      {isABTesting ? 'Generate A/B Test' : 'Make magic'}
    </button>
    {error && (
      <div className="p-4 mb-4 text-darkSlateGray-800 bg-vibrantCoral-100 rounded-lg">
        {error}
      </div>
    )}
  </div>
</div>

    
   <div className="w-full md:w-1/2 flex flex-col bg-whitesmoke-50 p-4 overflow-auto">
  {/* Top Div for Output Description */}
  <div className="flex-none p-4 border-b border-gray-300">
  <h1 className="text-xl md:text-2xl font-bold text-darkSlateGray-800 mb-6 text-center">Your Product Description</h1>
  {loading && <Loading />}
  <DescriptionOutput 
    isABTesting={isABTesting}
    descriptionA={descriptionA}
    descriptionB={descriptionB}
    description={description}
    onCopy={copyToClipboard}
  />
</div>


  {/* Bottom Div for Generated Descriptions */}
  <DescriptionList 
    descriptions={descriptions}
    onCopy={copyToClipboard}
  />

</div>

    </div>
    

    );
}
export default DescriptionGenerator;
